import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { StepPanel } from './StepPanel';

import { CommunityColorsMobile } from '@mainApp/src/components/common';
import { useMobileCtx } from '@mainApp/src/components/common/create-community-stepper/data';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { BottomSheetCollapseLevel } from '@foundationPathAlias/components/bottomsheet/BottomSheetCollapseLevel';
import { useBottomSheetCtx } from '@foundationPathAlias/components/bottomsheet/useBottomSheetCtx';
import { useEffect } from 'react';

export function _Step5Mobile() {
  const {
    steps: { step5 },
  } = useInjection(IOC_TOKENS.createCommunityStepperStore);

  const { selectedColorData, setSelectedColorData } = useMobileCtx();

  const { t } = useTranslation(['common', 'create-community']);
  const bottomsheetCtx = useBottomSheetCtx();
  const mobileCtx = useMobileCtx();

  useEffect(() => {
    if (!mobileCtx || !bottomsheetCtx) return;

    mobileCtx.setResetBottomSheetCtx(bottomsheetCtx.reset);
    return () => {
      // don't need it anymore because all other steps won't use it
      mobileCtx.setResetBottomSheetCtx(null);
    };
  }, [mobileCtx, bottomsheetCtx]);

  const {
    isBottomSheetIfFullWindowMode,
    setIsBottomSheetIfFullWindowMode,
    bottomSheetActions,
  } = mobileCtx;

  const communityColorsErrorMessages = {
    invalidHexFormatError: t('create-community:invalidHexFormatError'),
    tooLightColorError: t('create-community:tooLightColorError'),
    tooDarkColorError: t('create-community:tooDarkColorError'),
    useAlternativeColorLm: t('create-community:useAlternativeColorLm'),
    useAlternativeColorDm: t('create-community:useAlternativeColorDm'),
  };

  let content = (
    <CommunityColorsMobile
      key="CommunityColorsMobile"
      selectedColorData={selectedColorData}
      initialPrimaryColor={step5.initialPrimaryColor}
      setColor={(type, color) => {
        step5.setColor(type, color);
      }}
      lmLabel={t('create-community:communityLmColorTitle')}
      lmPrimaryColor={step5.lmPrimaryColor}
      setLmPrimaryBlack={step5.setLmPrimaryBlack}
      dmLabel={t('create-community:communityDmColorTitle')}
      dmPrimaryColor={step5.dmPrimaryColor}
      setDmPrimaryWhite={step5.setDmPrimaryWhite}
      errorMessages={communityColorsErrorMessages}
      onResetSelectedColorData={() => {
        setSelectedColorData(null);
      }}
      onColorPickerTrigger={(selectedColor) => {
        setSelectedColorData(selectedColor);
        bottomSheetActions.transformToFullWindow?.();
        setIsBottomSheetIfFullWindowMode(true);
      }}
      isFullWindowMode={isBottomSheetIfFullWindowMode}
    />
  );

  if (!isBottomSheetIfFullWindowMode) {
    content = (
      <StepPanel
        title={t('create-community:step5Title')}
        description={t('create-community:step5Description')}
        stepStore={step5}
      >
        <BottomSheetCollapseLevel idNumber={1} lastLevelToClose={true} />
        {content}
        <p className="mt-[24px] flex space-x-[4px] rounded-[8px] bg-color-14 px-[12px] py-[8px] dark:bg-color-14-dark">
          <span className="pt-[3px]">
            <InformationCircleIcon className=" themed-text-secondary h-[22px] w-[22px] " />
          </span>
          <span className="text-left">
            {t('create-community:step5ColorsHint')}
          </span>
        </p>
      </StepPanel>
    );
  }

  return content;
}

export const Step5Mobile = observer(_Step5Mobile);
