import { classNames } from '@foundationPathAlias/utilities';
import { XMarkIcon } from '@heroicons/react/20/solid';
export const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button
    className={classNames(
      'hover-el pressed-el-opacity animation-classic h-[22px] w-[22px] scale-125 outline-none'
    )}
    onClick={onClick}
  >
    <XMarkIcon className="fill-text-secondary dark:fill-text-secondary-dark" />
  </button>
);
