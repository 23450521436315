// for step 5 it creates a portal for actions because of the collapse functionality

import { observer } from 'mobx-react-lite';
import { createPortal } from 'react-dom';

import { AnimatedComponentsSwitcher } from '@10x/foundation/src/components/animations';

import { systemConfig } from '@mainApp/src/config';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useMobileCtx } from '../data';
import { Actions } from './Actions';
import { ActionsBottomsheetWindowMode } from './ActionsBottomsheetWindowMode';

export function _MobileActionsPanel() {
  const createCommunityStepperStore = useInjection(
    IOC_TOKENS.createCommunityStepperStore
  );

  const activeStepStore = createCommunityStepperStore.activeStepStore;
  const isStep5 = activeStepStore.id === 5;

  const { isBottomSheetIfFullWindowMode } = useMobileCtx();

  const content = (
    <div
      className="themed-layout absolute
    bottom-0 h-[72px] w-full px-[16px]"
      style={{
        zIndex: systemConfig.zIndexMax,
      }}
    >
      <AnimatedComponentsSwitcher
        cn="w-full relative flex h-full items-center"
        showA={!isBottomSheetIfFullWindowMode}
        componentA={<Actions cn="w-full absolute" />}
        componentB={<ActionsBottomsheetWindowMode cn="w-full absolute" />}
      />
    </div>
  );

  const renderContent = () =>
    isStep5 ? createPortal(content, document.body) : content;

  return createCommunityStepperStore.isModalOpened ? renderContent() : null;
}

export const MobileActionsPanel = observer(_MobileActionsPanel);
