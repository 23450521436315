import { classNames } from '@10x/foundation/src/utilities';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SuccessIcon } from '../icons/SuccessIcon';

type StepPreviewProps = {
  title: string;
  description: string;
  children?: React.ReactNode;
  stepStore: any;
};

export const _StepPanel = (props: StepPreviewProps) => {
  const { title, stepStore, description, children } = props;
  const createCommunityStepperStore = useInjection(
    IOC_TOKENS.createCommunityStepperStore
  );
  const { t } = useTranslation(['create-community']);
  const {
    id: currentActiveStepStoreId,
    isShowMoveBackError,
    setShowMoveBackError,
    canMoveNext: isCompletedActiveStep,
  } = createCommunityStepperStore.activeStepStore;
  const isActiveStep = currentActiveStepStoreId === stepStore?.id;

  const completed = !isActiveStep && stepStore?.canMoveNext;
  const canMoveToPrevStep = completed && isCompletedActiveStep;

  const showMoveBackError = isActiveStep && isShowMoveBackError;

  return (
    <div
      onClick={() => {
        // shouldn't allow to move to prev step if the current one isn't completed
        if (canMoveToPrevStep) {
          createCommunityStepperStore.setActiveStepStoreById(stepStore.id);
        } else if (completed) {
          /**
           * show error only when the click on the some other step panel
           * (not active) and only if that step is completed.
           *  */
          setShowMoveBackError(true);
        }
      }}
      className={'rounded-md'}
    >
      {completed ? (
        <span className="absolute right-[16px] top-[16px]">
          <SuccessIcon
            rootCn="mx-auto flex h-[22px] w-[22px] items-center justify-center rounded-full bg-primary-100"
            iconCn="h-[16px] w-[16px] text-white"
          />
        </span>
      ) : null}
      <div className="flex flex-col items-start">
        <h3 className={classNames('themed-text mb-[4px] text-body16SB')}>
          {title}
        </h3>
        <p
          className={classNames('themed-text-secondary text-body14R text-left')}
        >
          {description}
        </p>
        {showMoveBackError && (
          <small className="mt-[4px] block text-exstraSm12R text-element-error dark:text-element-error-dark">
            {t('create-community:moveBackError')}
          </small>
        )}
      </div>

      {isActiveStep ? children : null}
    </div>
  );
};

export const StepPanel = observer(_StepPanel);
