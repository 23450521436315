import { classNames } from '@10x/foundation/src/utilities';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { IStep1Store, IStep2Store, IStep5Store } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';

import { useMobileCtx } from '@mainApp/src/components/common/create-community-stepper/data';
import { useTranslation } from 'react-i18next';

// TS type guard
function isStep5Store(obj: any): obj is IStep5Store {
  return typeof obj === 'object' && obj !== null && obj.id === 5;
}

type ActionsBottomsheetWindowModeProps = {
  cn?: string;
  firstBtnCn?: string;
  secondBtnCn?: string;
};

export const _ActionsBottomsheetWindowMode = (
  props: ActionsBottomsheetWindowModeProps
) => {
  const { cn, firstBtnCn, secondBtnCn } = props;
  const { t } = useTranslation(['common']);
  const createCommunityStepperStore = useInjection(
    IOC_TOKENS.createCommunityStepperStore
  );

  const {
    restoreBottomSheetToNormalMode,
    setSelectedColorData,
    selectedColorData,
  } = useMobileCtx();

  const currentStepStore = createCommunityStepperStore?.activeStepStore as
    | IStep1Store
    | IStep2Store
    | IStep5Store;

  let secondActionLabelKey;
  let secondAction: () => void;

  if (isStep5Store(currentStepStore)) {
    secondActionLabelKey = 'save';
    secondAction = () => {
      // shouldn't happen because on the fullwindow mode it's automatically loads but anyway
      if (!selectedColorData) {
        throw new Error('selectedColorData is not defined');
      }
      currentStepStore.setIsColorSaved(selectedColorData.type, true);
      setSelectedColorData(null);
      restoreBottomSheetToNormalMode();
    };
  } else {
    secondActionLabelKey = 'upload.label';
    secondAction = currentStepStore.triggerUploading;
  }

  return (
    <div className={classNames('flex h-[40px]', cn)}>
      <button
        className={classNames(
          'hover-el-opacity themed-text radius-[2px] text-body14R h-full rounded-[5px] border border-[element-normal] px-[24px]',
          firstBtnCn
        )}
        onClick={() => {
          currentStepStore.reset();
          restoreBottomSheetToNormalMode();
        }}
      >
        {t('cancel')}
      </button>
      <button
        className={classNames(
          'hover-el-opacity text-body14R ml-[16px] h-full flex-1 rounded-[5px] bg-primary-100 px-[24px] text-text-primary-dark md:min-w-[160px]',
          secondBtnCn
        )}
        onClick={() => {
          restoreBottomSheetToNormalMode();
          secondAction?.();
        }}
      >
        {t(secondActionLabelKey)}
      </button>
    </div>
  );
};

export const ActionsBottomsheetWindowMode = observer(
  _ActionsBottomsheetWindowMode
);
