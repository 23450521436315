// Used for the Create Community Stepper because previously it was Onboarding
import {
  UserOnboardingDataInput,
  UserOnboardingPayload,
} from '@10x/foundation/types';
import axios from 'axios';
import { inject, injectable } from 'inversify';
import {
  COMPLETE_USER_ONBOARDING_ALL_STEPS,
  COMPLETE_USER_ONBOARDING_STEP,
  GENERATE_COMMUNITY_LOGO_IMAGE_PRESIGNED_POST_URL,
  GENERATE_COMMUNITY_THUMBNAIL_IMAGE_PRESIGNED_POST_URL,
  GET_COMMUNITY_LOGO_IMAGE,
  GET_COMMUNITY_THUMBNAIL_IMAGE,
  GET_USER_POST_ONBOARDING_DATA,
} from '../graphql/queries';

import { BaseRepositoryResponse } from './types';

import { IOC_TOKENS } from '../ioc';
import { Client } from './types';

type UploadedImageResponse = GenerateCommunityImageResponse & {
  loadedUrls: string[];
};

@injectable()
export class OnboardingRepository implements IOnboardingRepositoryInterface {
  gqlClient;

  // currently it's urql
  constructor(@inject(IOC_TOKENS.graphqlClient) gqlClient: Client) {
    this.gqlClient = gqlClient;
  }
  async uploadThumbnail(
    file: File,
    callback: (
      uploadPercent: number,
      uploadedImgUrlId: string,
      abortUpload: () => void
    ) => void,
    id?: string
  ) {
    const controller = new AbortController();
    const { signal } = controller;
    const abortUpload = () => controller.abort();

    const response = await this.gqlClient
      .mutation(GENERATE_COMMUNITY_THUMBNAIL_IMAGE_PRESIGNED_POST_URL, {
        id: id,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    const res = {
      data: data?.generateCommunityThumbnailImagePreSignedPostUrl,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    const { url, id: urlId } =
      data?.generateCommunityThumbnailImagePreSignedPostUrl;
    const formData = new FormData();
    formData.append('file', file);

    const axiosResponse = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent: any) {
        if (!progressEvent) return;

        const { loaded, total = 1 } = progressEvent;

        const loadedPercent = Math.round((loaded / total) * 100);
        callback(loadedPercent, urlId, abortUpload);
      },
      signal,
    });

    const combinedRes = {
      ...res.data,
      loadedUrls: axiosResponse?.data?.result?.variants,
    };

    return await combinedRes;
  }

  async uploadLogo(
    file: File,
    callback: (
      uploadPercent: number,
      urlId: string,
      abortUpload: () => void
    ) => void,
    id?: string
  ) {
    const controller = new AbortController();
    const { signal } = controller;
    const abortUpload = () => controller.abort();

    const response = await this.gqlClient
      .mutation(GENERATE_COMMUNITY_LOGO_IMAGE_PRESIGNED_POST_URL, {
        id: id,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data, error } = response;

    // this response isn't using but I leave it for the error handling if would be needed
    const res = {
      data: data?.generateCommunityLogoImagePreSignedPostUrl,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    const { url, id: urlId } = data?.generateCommunityLogoImagePreSignedPostUrl;
    const formData = new FormData();
    formData.append('file', file);

    const axiosResponse = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent) {
        if (!progressEvent) return;
        const { loaded, total = 1 } = progressEvent;

        const loadedPercent = Math.round((loaded / total) * 100);
        callback(loadedPercent, urlId, abortUpload);
      },
      signal,
    });
    const combinedRes = {
      ...res.data,
      loadedUrls: axiosResponse?.data?.result?.variants,
    };

    return await combinedRes;
  }

  // @ts-ignore
  async getCommunityImage(type: 'thumbnail' | 'logo', id: string) {
    const query =
      type === 'thumbnail'
        ? GET_COMMUNITY_THUMBNAIL_IMAGE
        : GET_COMMUNITY_LOGO_IMAGE;
    const response = await this.gqlClient
      .query(query, {
        id: id,
      })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?
    const { data } = response;

    return data?.[
      type === 'thumbnail' ? 'communityThumbnailImage' : 'communityLogoImage'
    ]?.imageUrls;
  }

  getUserOnboardingData = async () => {
    const response = await this.gqlClient
      .query(GET_USER_POST_ONBOARDING_DATA, {})
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?

    const { data, error } = response;

    const res = {
      data: data?.userOnboarding,
      error: error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  completeUserOnboardingStep = async (data: UserOnboardingDataInput) => {
    const response = await this.gqlClient
      .mutation(COMPLETE_USER_ONBOARDING_STEP, { data })
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?

    const res = {
      data: response.data?.completeUserOnboardingStep,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };

  completeUserOnboardingAllSteps = async () => {
    const response = await this.gqlClient
      .mutation(COMPLETE_USER_ONBOARDING_ALL_STEPS, {})
      .toPromise();
    // todo: maybe some base class with base method to always use it for eveywhere?

    const res = {
      data: response.data?.completeUserOnboardingAllSteps,
      error: response.error,
      pageInfo: null,
      originalResponse: response,
    };

    return res;
  };
}

export type UploadImagePrecentCallback = (
  uploadPercent: number,
  uploadedImgUrlId: string,
  abortUpload: () => void
) => void;

export type GenerateCommunityImageResponse = {
  id: string;
  url: string;
  fileds: {
    key: string;
    value: string;
  }[];
};

export interface IOnboardingRepositoryInterface {
  gqlClient: Client;

  uploadThumbnail(
    file: File,
    callback: UploadImagePrecentCallback,
    id?: string
  ): Promise<UploadedImageResponse>;
  uploadLogo(
    file: File,
    callback: UploadImagePrecentCallback,
    id?: string
  ): Promise<UploadedImageResponse>;
  getCommunityImage(type: 'thumbnail' | 'logo', id: string): Promise<string[]>;
  getUserOnboardingData(): Promise<
    BaseRepositoryResponse<UserOnboardingPayload>
  >;
  completeUserOnboardingStep: (
    data: UserOnboardingDataInput
  ) => Promise<BaseRepositoryResponse<UserOnboardingPayload>>;
  completeUserOnboardingAllSteps: () => Promise<
    BaseRepositoryResponse<UserOnboardingPayload>
  >;
}
