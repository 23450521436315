import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import type { StepKey } from '@mainApp/src/stores/create-community-stepper/CreateCommunityStepper.store';

import { Step1 } from './Step1';
import { Step1Mobile } from './Step1Mobile';
import { Step2 } from './Step2';
import { Step2Mobile } from './Step2Mobile';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { Step5Mobile } from './Step5Mobile';

type StepsComponentsRegistry = {
  [key in StepKey]: {
    mobile: React.ComponentType;
    desktop: React.ComponentType;
  };
};

const stepsComponentsRegistry: StepsComponentsRegistry = {
  step1: {
    mobile: Step1Mobile,
    desktop: Step1,
  },
  step2: {
    mobile: Step2Mobile,
    desktop: Step2,
  },
  step3: {
    mobile: Step3,
    desktop: Step3,
  },
  step4: {
    mobile: Step4,
    desktop: Step4,
  },
  step5: {
    mobile: Step5Mobile,
    desktop: Step5,
  },
};

export function useGetActiveStepComponent() {
  const {
    createCommunityStepperStore: { activeStepStoreKey },
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.createCommunityStepperStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  return stepsComponentsRegistry[activeStepStoreKey][
    isMobile ? 'mobile' : 'desktop'
  ];
}
