import { classNames } from '@foundationPathAlias/utilities';
import { useEffect, useRef } from 'react';
import { useBottomSheetCtx } from './useBottomSheetCtx';

// ATTENTION: it's based on offset top so it's important to not put collapse levels inside the wrapper with relative position except the main wrapper that is the bottomsheet content wrapper itself. Check the DOM of the examples in the storybook or in the app to understand how it works

type Props = {
  predefinedLevelVal?: number | null;
  lastLevelToClose?: boolean;
  // 1n+. Collapse levels in a new Bottomsheet instance should start from 1. 0 is reserved for the initial state
  idNumber: number;
  cn?: string;
};

export function BottomSheetCollapseLevel(props: Props) {
  const { predefinedLevelVal, lastLevelToClose, idNumber, cn } = props;
  const elRef = useRef<HTMLHRElement | null>(null);

  const bottomSheetCtx = useBottomSheetCtx();

  if (!bottomSheetCtx) {
    throw new Error(
      'useBottomSheetCtx must be used within a BottomSheetCtxProvider'
    );
  }

  useEffect(() => {
    if (!elRef.current) return;
    requestAnimationFrame(() => {
      if (!elRef.current) return;
      const rect = elRef.current.getBoundingClientRect();

      bottomSheetCtx?.addCollapseLevelData({
        el: elRef.current,
        orderNumberId: idNumber,
        predefinedLevelVal: predefinedLevelVal,
        levelVal: rect.top,
        lastLevelToClose,
      });
    });
  }, [elRef.current]);

  return <div className={classNames('h-[1px] w-full', cn)} ref={elRef} />;
}
