import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { CommunityColors } from '@mainApp/src/components/common';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { StepPanel } from './StepPanel';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

export function _Step5() {
  const {
    steps: { step5 },
  } = useInjection(IOC_TOKENS.createCommunityStepperStore);

  const { t } = useTranslation(['common', 'create-community']);

  const communityColorsErrorMessages = {
    invalidHexFormatError: t('create-community:invalidHexFormatError'),
    tooLightColorError: t('create-community:tooLightColorError'),
    tooDarkColorError: t('create-community:tooDarkColorError'),
    useAlternativeColorLm: t('create-community:useAlternativeColorLm'),
    useAlternativeColorDm: t('create-community:useAlternativeColorDm'),
  };

  return (
    <StepPanel
      title={t('create-community:step5Title')}
      description={t('create-community:step5Description')}
      stepStore={step5}
    >
      <CommunityColors
        initialPrimaryColor={step5.initialPrimaryColor}
        setColor={step5.setColor}
        lmLabel={t('create-community:communityLmColorTitle')}
        lmPrimaryColor={step5.lmPrimaryColor}
        setLmPrimaryBlack={step5.setLmPrimaryBlack}
        dmLabel={t('create-community:communityDmColorTitle')}
        dmPrimaryColor={step5.dmPrimaryColor}
        setDmPrimaryWhite={step5.setDmPrimaryWhite}
        errorMessages={communityColorsErrorMessages}
      />
      <p className="dark:bg-color-14-dark mt-[24px] flex space-x-[4px] rounded-[8px] bg-color-14 px-[12px] py-[8px]">
        <span className="pt-[3px]">
          <InformationCircleIcon className=" themed-text-secondary h-[22px] w-[22px] " />
        </span>
        <span className="text-left">
          {t('create-community:step5ColorsHint')}
        </span>
      </p>
    </StepPanel>
  );
}

export const Step5 = observer(_Step5);
