import { ColorType } from './types';

type CommunityColorPickerPropsType = {
  color: ColorType;
  onTrigger: () => void;
};

export function CommunityColorPickerMobile(
  props: CommunityColorPickerPropsType
) {
  const { color, onTrigger } = props;
  return (
    <button
      className="h-[32px] w-[32px] rounded-[3px]"
      style={{
        backgroundColor: color.rgba,
      }}
      onClick={onTrigger}
    />
  );
}
