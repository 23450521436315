import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { RedirectionIdsEnum } from '@mainApp/src/services';
import { useCallback } from 'react';
import { InteractiveContentTypesEnum } from '../stores';
export function useShowAuthModalAction() {
  const { interactiveStore, authStore } = useMultipleInjection([
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.authStore,
  ]);
  return useCallback((isLoginMode?: boolean) => {
    if (!authStore.logged) {
      authStore.setLoginMode(Boolean(isLoginMode));

      authStore.saveAuthCallerUrl(RedirectionIdsEnum.AUTH_REQUIRED);

      interactiveStore.setActiveContentType(
        InteractiveContentTypesEnum.AUTH,
        true
      );
    }
  }, []);
}
