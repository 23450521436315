import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { useTranslation } from 'react-i18next';

import { classNames } from '@foundationPathAlias/utilities';
import { StepContentTitle } from '@mainApp/src/components/common/create-community-stepper/shared/StepContentTitle';
import { YourCommunityCard } from '@mainApp/src/components/common/create-community-stepper/shared/community-card/YourCommunityCard';
import { paths } from '@mainApp/src/config/paths';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

export function _CompletedUI() {
  const {
    createCommunityStepperStore,
    systemStore,
    communityStore,
    authStore,
    dimensionsStore: { isMobile },
    interactiveStore,
  } = useMultipleInjection([
    IOC_TOKENS.createCommunityStepperStore,
    IOC_TOKENS.systemStore,
    IOC_TOKENS.communityStore,
    IOC_TOKENS.authStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.interactiveStore,
  ]);
  const {
    steps: { step5: step5Store },
  } = createCommunityStepperStore;

  const { isDarkTheme } = systemStore;

  const { t } = useTranslation(['common', 'create-community']);

  const router = useRouter();

  const action = authStore.logged ? (
    <button
      className={classNames(
        'themed-text-inverted body18SB flex w-full items-center justify-center rounded-[5px] bg-primary-100 px-[16px] py-[9px]',
        !isMobile && 'mt-[16px]'
      )}
      disabled={communityStore.creatingCommunity}
      style={{
        backgroundColor: isDarkTheme
          ? step5Store.dmPrimaryColor?.rgba ||
            step5Store.initialPrimaryColor.rgba
          : step5Store.lmPrimaryColor?.rgba ||
            step5Store.initialPrimaryColor.rgba,
      }}
      onClick={() => {
        interactiveStore.setContent(null);
        interactiveStore.setInteractiveElementOpen(false);

        const isOnboardingModelCreated =
          createCommunityStepperStore.isCommunityCreated;

        /**
         * It's a second time when the user opens this model
         * after the success community creation
         *  */
        if (isOnboardingModelCreated) {
          createCommunityStepperStore.redirectToCreatedCommunity();
          createCommunityStepperStore.setModal(false);
        }
      }}
    >
      {t('create-community:seeMyCommunity')}
    </button>
  ) : (
    <>
      <button
        className={
          'themed-text-inverted hover-el-opacity body18SB mt-[16px] flex w-full items-center justify-center rounded-[5px] bg-primary-100 px-[16px] py-[9px]'
        }
        style={{
          backgroundColor: isDarkTheme
            ? step5Store.dmPrimaryColor?.rgba ||
              step5Store.initialPrimaryColor.rgba
            : step5Store.lmPrimaryColor?.rgba ||
              step5Store.initialPrimaryColor.rgba,
        }}
        onClick={() => {
          createCommunityStepperStore.setModal(false);
          router.push(paths.signup);
        }}
      >
        {t('create-community:signUpToSave')}
      </button>
      <p className="themed-text mt-[16px] flex justify-center text-body16R">
        {t('create-community:haveAnAccount')}{' '}
        <button
          className="text-action-primary ml-[8px]"
          onClick={() => {
            createCommunityStepperStore.setModal(false);
            router.push(paths.login);
          }}
        >
          {t('navbar.logIn')}
        </button>
      </p>
    </>
  );

  const content = (
    <div>
      {!isMobile && <div className="h-[46px]" />}

      <StepContentTitle
        title={t('create-community:ready')}
        description={t('create-community:readyDescription')}
        cn="text-center"
        titleCn="text-miniHead"
        descriptionCn="themed-text-secondary"
      />
      <div className="mb-[32px] mt-[20px] flex justify-center">
        <YourCommunityCard cn="max-w-[380px]" />
      </div>
      {action}
    </div>
  );

  return content;
}

export const CompletedUI = observer(_CompletedUI);
