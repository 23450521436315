import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '@10x/foundation/src/utilities';

import { AnimatedFadeWrapper } from '@10x/foundation/src/components/animations';
import { BackButton } from '@foundationPathAlias/components';
import { BottomSheetCtxProvider } from '@foundationPathAlias/components/bottomsheet/BottomSheetContext';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { useMobileCtx } from './data';
import { CloseButton } from './shared/CloseButton';
import { MobileActionsPanel } from './shared/MobileActionsPanel';
import { StepNumber } from './shared/StepNumber';
import { useGetActiveStepComponent } from './steps-panel-column/steps/useGetActiveStepComponent';

const BottomSheetFnd = dynamic(
  () =>
    import('@10x/foundation/src/components/bottomsheet/BottomSheet').then(
      (mod) => mod.BottomSheet
    ),
  {
    ssr: false,
  }
);

type Props = {
  activeStepContent: {
    mobile: JSX.Element;
    desktop: JSX.Element;
  };
};

export const _CreateCommunityStepperMobileContent = (props: Props) => {
  const { activeStepContent } = props;
  const createCommunityStepperStore = useInjection(
    IOC_TOKENS.createCommunityStepperStore
  );

  const ActiveStepComponent = useGetActiveStepComponent();

  const { t } = useTranslation(['common', 'create-community']);

  const {
    bottomSheetActions,
    setBottomSheetActions,
    isBottomSheetIfFullWindowMode,
    setIsBottomSheetIfFullWindowMode,
  } = useMobileCtx();

  const activeStepStore = createCommunityStepperStore.activeStepStore;
  const isStep5 = activeStepStore.id === 5;
  const handleClose = () => {
    createCommunityStepperStore.setModal(false);
  };

  useEffect(() => {
    if (bottomSheetActions.open) {
      bottomSheetActions.open();
    }
  }, [bottomSheetActions.open]);

  return (
    <div className="themed-layout themed-text relative flex flex-1 flex-col md:w-auto">
      <div className="grid h-[56px] grid-cols-[60px_1fr_68px] items-center justify-between border-b-[1px] border-b-element-normal px-[8px] dark:border-b-element-normal-dark">
        <div className="flex items-center justify-start">
          {createCommunityStepperStore.canMoveBack && (
            <BackButton
              className="dark:text-secondary-dark text-text-secondary"
              onClick={() => {
                createCommunityStepperStore.backToPrevStep();
                bottomSheetActions.resetBottomSheetCtx?.();
              }}
            />
          )}
        </div>

        <div>
          {' '}
          <h4 className="text-body18SB">
            {t('create-community:createCommunity')}
          </h4>
        </div>

        <div className="flex items-center justify-end pr-[8px]">
          <CloseButton onClick={handleClose} />
        </div>
      </div>

      <div className="flex flex-1 justify-center bg-surface-onBase-secondary pt-[32px]">
        {activeStepContent.mobile}
        <BottomSheetCtxProvider>
          <BottomSheetFnd
            // step 5 has collapse levels so they will be handled by them in Step5Mobile
            preventClose={!isStep5}
            // 72 is actions panel height and 24 is the gap by collapse level and bottom regarding mockups
            collapseLevelBottomGap={72 + 24}
            className={classNames(
              'themed-layout flex shadow-shadow-menu-mobile-top'
            )}
            disableTouchMove={isBottomSheetIfFullWindowMode}
            noBackdrop={true}
            getActions={(
              open,
              close,
              transformToFullWindow,
              transformToOriginal
            ) => {
              setBottomSheetActions({
                open,
                close,
                transformToFullWindow,
                transformToOriginal,
              });
            }}
            onClose={() => {
              createCommunityStepperStore.setModal(false);
            }}
          >
            <div
              className="h-full pb-[72px] pt-[8px]"
              style={{
                position: isStep5 ? 'static' : 'relative',
              }}
            >
              {isBottomSheetIfFullWindowMode && <div className="h-[26px]" />}
              <AnimatedFadeWrapper show={isBottomSheetIfFullWindowMode}>
                <div className="absolute -top-[60px] grid h-[52px] w-full grid-cols-[32px_1fr_32px] items-center  border-b-[1px] border-element-subtle  pl-[8px] pr-[16px] dark:border-element-subtle-dark">
                  <BackButton
                    className="dark:text-secondary-dark text-text-secondary"
                    onClick={() => {
                      activeStepStore?.reset();
                      bottomSheetActions.transformToOriginal?.();
                      setIsBottomSheetIfFullWindowMode(false);
                    }}
                  />
                  <span className="themed-text text-body16SB">
                    {t(
                      activeStepStore.id === 5
                        ? 'common:pickAColor'
                        : 'common:cropImage'
                    )}
                  </span>
                </div>
              </AnimatedFadeWrapper>

              <div className="mb-[24px] px-[16px]">
                {!isBottomSheetIfFullWindowMode && <StepNumber />}

                <ActiveStepComponent />
              </div>

              <MobileActionsPanel />
            </div>
          </BottomSheetFnd>
        </BottomSheetCtxProvider>
      </div>
    </div>
  );
};

export const CreateCommunityStepperMobileContent = observer(
  _CreateCommunityStepperMobileContent
);
