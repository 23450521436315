export class DoubleLinListNode<T> {
  id: string;
  model: T;

  prev: DoubleLinListNode<T> | null = null;
  next: DoubleLinListNode<T> | null = null;

  orderIndex: number | null = null;

  constructor(model: T, id: string) {
    this.id = id;
    this.model = model;
  }
}
