import { classNames } from '@10x/foundation/src/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

import { SmallLoader } from '@foundationPathAlias/components/loaders';
import { useTranslation } from 'react-i18next';

import { useShowCompleteUIMobile } from './useShowCompleteUIMobile';

type ActionsProps = {
  cn?: string;
  firstBtnCn?: string;
  secondBtnCn?: string;
  onNextClick?: (stepNumber: number) => void;
  onCancel?: () => void;
};

export const _Actions = (props: ActionsProps) => {
  const { onCancel, cn, firstBtnCn, secondBtnCn } = props;
  const { t } = useTranslation(['common', 'create-community']);

  const showCompleteUIMobile = useShowCompleteUIMobile();
  const {
    createCommunityStepperStore,
    dimensionsStore: { isMobile },
    authStore: { logged },
    communityStore,
  } = useMultipleInjection([
    IOC_TOKENS.createCommunityStepperStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.authStore,
    IOC_TOKENS.communityStore,
  ]);

  const currentStepStore = createCommunityStepperStore?.activeStepStore;
  const canMoveNext = currentStepStore?.canMoveNext;

  const startCommunityCreation = async (nextStepNumber: number) => {
    await createCommunityStepperStore.proceedCommunityCreation().finally(() => {
      createCommunityStepperStore.setActiveStepStoreById(nextStepNumber);
    });
    if (isMobile) {
      showCompleteUIMobile();
    }
  };

  return (
    <div className={classNames('flex h-[40px]', cn)}>
      <button
        className={classNames(
          'hover-el-opacity themed-text radius-[2px] text-body14R h-full rounded-[5px] border border-[element-normal] px-[24px]',
          firstBtnCn
        )}
        onClick={() => {
          createCommunityStepperStore.setModal(false);
          createCommunityStepperStore.reset();
          onCancel?.();
        }}
      >
        {t('cancel')}
      </button>
      <button
        disabled={!canMoveNext || communityStore.creatingCommunity}
        className={classNames(
          'text-body14R ml-[16px] flex h-full flex-1 items-center justify-center rounded-[5px] bg-primary-100 px-[24px] text-text-primary-dark md:min-w-[160px]',
          secondBtnCn,
          !isMobile && 'hover-el-opacity',
          !canMoveNext && 'disabled'
        )}
        onClick={() => {
          const nextStepNumber = currentStepStore.id + 1;

          if (nextStepNumber > 5) {
            if (logged) {
              startCommunityCreation(nextStepNumber);
            } else {
              if (isMobile) {
                showCompleteUIMobile();
              } else {
                createCommunityStepperStore.setActiveStepStoreById(
                  nextStepNumber
                );
              }
            }
          } else {
            createCommunityStepperStore.setActiveStepStoreById(nextStepNumber);
          }
        }}
      >
        {communityStore.creatingCommunity && <SmallLoader cn="mr-[6px]" />}
        {t(
          currentStepStore.id === 5 &&
            createCommunityStepperStore.isAllStepsReady
            ? communityStore.creatingCommunity
              ? 'create-community:creatingCommunity'
              : 'create-community:createCommunity'
            : 'common:next'
        )}
      </button>
    </div>
  );
};

export const Actions = observer(_Actions);
