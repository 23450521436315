import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { FormFieldInput } from '@mainApp/src/components/common';
import { StepPanel } from './StepPanel';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

export function _Step4() {
  const {
    steps: { step4 },
  } = useInjection(IOC_TOKENS.createCommunityStepperStore);

  const { t } = useTranslation(['common', 'create-community']);

  return (
    <StepPanel
      title={t('create-community:step4Title')}
      description={t('create-community:step4Description')}
      stepStore={step4}
    >
      <FormFieldInput
        onChange={(val) => {
          step4.setTopicValue(1, {
            value: val,
            error: step4.topics.topic1.error,
          });
        }}
        onError={(error) => {
          step4.setTopicValue(1, {
            value: step4.topics.topic1.value,
            error: error,
          });
        }}
        maxCharsAtRight={true}
        value={step4.topics.topic1.value}
        maxCharsLimit={24}
        inputName="communityTopic1"
        placeholder={t('create-community:step4Topic1Placeholder') as string}
      />
      <FormFieldInput
        onChange={(val) => {
          step4.setTopicValue(2, {
            value: val,
            error: step4.topics.topic2.error,
          });
        }}
        onError={(error) => {
          step4.setTopicValue(2, {
            value: step4.topics.topic2.value,
            error: error,
          });
        }}
        maxCharsAtRight={true}
        value={step4.topics.topic2.value}
        maxCharsLimit={24}
        inputName="communityTopic2"
        placeholder={t('create-community:step4Topic2Placeholder') as string}
      />
    </StepPanel>
  );
}

export const Step4 = observer(_Step4);
