'use client';
import {
  TextInput,
  TextInputRefType,
  validators,
} from '@foundationPathAlias/components';
import { hexToRGBA, rgbaToValuesObject } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { initialColor } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityColorPicker } from './CommunityColorPicker';
import {
  GetColorContrastValidatorErrorMessages,
  getColorContrastValidator,
} from './getColorContrastValidator';
import { ColorType } from './types';

type CommunityColorsProps = {
  initialPrimaryColor: ColorType;
  errorMessages: GetColorContrastValidatorErrorMessages;
  dmLabel: string;
  dmPrimaryColor: ColorType | null;
  lmLabel: string;
  lmPrimaryColor: ColorType | null;
  setColor: (type: 'dm' | 'lm', color: ColorType) => void;
  setLmPrimaryBlack: () => void;
  setDmPrimaryWhite: () => void;
};

const _CommunityColors = (props: CommunityColorsProps) => {
  const {
    initialPrimaryColor,
    errorMessages,
    dmLabel,
    dmPrimaryColor,
    lmLabel,
    lmPrimaryColor,
    setLmPrimaryBlack,
    setDmPrimaryWhite,
    setColor,
  } = props;

  const lmInputRef = React.useRef<TextInputRefType | null>(null);
  const dmInputRef = React.useRef<TextInputRefType | null>(null);
  const { t } = useTranslation(['common']);

  const dimensionsStore = useInjection(IOC_TOKENS.dimensionsStore);

  const lmColor = lmPrimaryColor || initialPrimaryColor;
  const dmColor = dmPrimaryColor || initialPrimaryColor;

  React.useEffect(() => {
    if (!lmInputRef.current || !lmColor) {
      return;
    }

    if (lmInputRef.current.getValue() !== lmColor.hex) {
      lmInputRef.current.setValue(lmColor.hex);
      if (!lmPrimaryColor) {
        setColor('lm', lmColor);
      }
    }
  }, [lmColor]);

  React.useEffect(() => {
    if (!dmInputRef.current || !dmColor) {
      return;
    }

    if (dmInputRef.current.getValue() !== dmColor.hex) {
      dmInputRef.current.setValue(dmColor.hex);

      if (!dmPrimaryColor) {
        setColor('dm', dmColor);
      }
    }
  }, [dmColor]);

  const getInputHandler =
    (type: 'dm' | 'lm') =>
    (val = '') => {
      // @ts-ignore
      const pureRgba = props[`${type}PrimaryColor`].pureRgba;
      const rgba = hexToRGBA(val, pureRgba.a);

      const finalColor = {
        rgba: rgba || initialColor.rgba,
        hex: val,
        pureRgba: rgba ? rgbaToValuesObject(rgba) : pureRgba,
      };
      setColor(type, finalColor);
    };

  const maxCharsLimit = 7;
  const maxCharValidatorErr = t('errors.maxCharLength', {
    maxCharCount: maxCharsLimit,
  });

  return (
    <div className="text-left">
      <div className="mt-[24px]">
        <label
          htmlFor="lmPrimaryColor"
          className="mb-[4px] block text-body16SB text-text-primary dark:text-text-primary-dark"
        >
          {lmLabel}
        </label>
        <TextInput
          labelCn="items-center"
          inputName="lmPrimaryColor"
          iconWrapperCn="w-[28px] h-[28px]"
          ref={(inputRef: TextInputRefType) => {
            if (lmInputRef.current) return;
            lmInputRef.current = inputRef;
          }}
          rightSideContent={
            <CommunityColorPicker
              isMobileMode={dimensionsStore.isMobile}
              color={lmColor as ColorType}
              onColorPick={(color: ColorType) => {
                setColor('lm', color);
              }}
            />
          }
          validators={[
            validators.getMaxLengthValidator(
              maxCharsLimit,
              maxCharValidatorErr
            ),
            getColorContrastValidator({
              type: 'lm',
              onSetColorClick: setLmPrimaryBlack,
              errorMessages,
            }),
          ]}
          onChange={getInputHandler('lm')}
        />
      </div>
      <div className="relative mt-[24px]">
        <label
          htmlFor="dmPrimaryColor"
          className="mb-[4px] block text-body16SB text-text-primary dark:text-text-primary-dark"
        >
          {dmLabel}
        </label>
        <TextInput
          labelCn="items-center"
          inputName="dmPrimaryColor"
          iconWrapperCn="w-[28px] h-[28px]"
          ref={(inputRef: TextInputRefType) => {
            if (dmInputRef.current) return;
            dmInputRef.current = inputRef;
          }}
          rightSideContent={
            <CommunityColorPicker
              isMobileMode={dimensionsStore.isMobile}
              color={dmColor as ColorType}
              onColorPick={(color: ColorType) => {
                setColor('dm', color);
              }}
            />
          }
          validators={[
            validators.getMaxLengthValidator(
              maxCharsLimit,
              maxCharValidatorErr
            ),
            getColorContrastValidator({
              type: 'dm',
              onSetColorClick: setDmPrimaryWhite,
              errorMessages,
            }),
          ]}
          onChange={getInputHandler('dm')}
        />
      </div>
    </div>
  );
};

export const CommunityColors = observer(_CommunityColors);
