import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useTranslation } from 'react-i18next';

export const _StepNumber = () => {
  const {
    steps,
    activeStepStore: { id: stepNumber },
  } = useInjection(IOC_TOKENS.createCommunityStepperStore);

  const totalStepsLn = Object.keys(steps).length;

  const { t } = useTranslation('create-community');

  return (
    <span
      className={
        'mb-[4px] block text-left text-sm14R text-text-secondary dark:text-text-secondary-dark'
      }
    >
      {t('stepNumberOfTotalSteps', {
        currentStep: stepNumber,
        totalSteps: totalStepsLn,
      })}
    </span>
  );
};

export const StepNumber = _StepNumber;
