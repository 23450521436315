import { YourCommunityCard } from '../shared/community-card/YourCommunityCard';

export function CommunityPreview() {
  return (
    <div className="flex w-full max-w-[584px] items-center justify-center">
      <div className="flex w-full max-w-[360px] rounded-[20px] md:shadow-floating-sheet">
        <YourCommunityCard />
      </div>
    </div>
  );
}
