import { action, computed, makeObservable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import { LoadingStatusEnum } from '../types';
import { CommunityStepperModel } from './CommunityStepper.model';

import { CommunityImageType } from './CreateCommunityStepper.types';
import { IStepBase } from './StepBase';

import { CropperSnapshot } from '@foundationPathAlias/components';
import { IOnboardingRepositoryInterface } from '@mainApp/src/repositories';
import { CanceledError } from 'axios';
import { StepBase } from './StepBase';

enableStaticRendering(typeof window === 'undefined');
export class Step2Store extends StepBase implements IStep2Store {
  id = 2;

  cropperSnapshot: CropperSnapshot | null = null;
  uploadingAbortCb: null | (() => void) = null;

  get logo() {
    return this.communityStepperModel.logo;
  }

  get canMoveNext() {
    return this.logo.status === LoadingStatusEnum.success;
  }

  get isUploading() {
    return this.logo.status === LoadingStatusEnum.uploading;
  }

  constructor(
    repository: IOnboardingRepositoryInterface,
    communityStepperModel: CommunityStepperModel
  ) {
    super(repository, communityStepperModel);

    makeObservable(this, {
      setLogo: action,
      reset: action,
      canMoveNext: computed,
      logo: computed,
    });
  }
  setCropperSnapshot = (snapshot: CropperSnapshot) => {
    this.cropperSnapshot = snapshot;
  };

  setLogo = (file: File, originalFileUrl?: string) => {
    if (!file) return;
    this.resetMoveBackError();
    const url = URL.createObjectURL(file);

    if (this.isUploading) return;

    this.communityStepperModel.setImage('logo', {
      file: file,
      fileName: file.name ? file.name : this.logo.fileName,
      fileSize: (file.size * 0.000001).toFixed(2) + ' MB',
      fileUrl: originalFileUrl ? originalFileUrl : this.logo.fileUrl,
      croppedFileUrl: url,
      status: LoadingStatusEnum.initial,
    });
  };

  triggerUploading = async () => {
    this.communityStepperModel.logo = {
      ...this.logo,
      uploadingPercent: 0,
      status: LoadingStatusEnum.uploading,
    };

    try {
      const res = await this.repository.uploadLogo(
        this.logo.file as File,
        (uploadPercent, uploadedImgUrlId, abortUpload) => {
          this.uploadingAbortCb = abortUpload;
          if (uploadPercent !== 100) {
            this.communityStepperModel.setImage('logo', {
              ...this.logo,
              uploadingPercent: uploadPercent,
            });
          } else {
            this.communityStepperModel.setImage('logo', {
              ...this.logo,
              id: uploadedImgUrlId,
              status: LoadingStatusEnum.success,
            });
          }
        }
      );

      if (res.loadedUrls) {
        const imgUrl = await this.communityStepperModel.getServerLoadedUrl(
          'logo',
          res.id
        );

        this.communityStepperModel.setImage('logo', {
          ...this.logo,
          uploadedUrl: imgUrl,
        });
      }
    } catch (e: any) {
      if (e.name === CanceledError.name) {
        return;
      }

      this.communityStepperModel.setImage('logo', {
        ...this.logo,
        error: e.message,
        status: LoadingStatusEnum.error,
      });
    }
  };

  reset = () => {
    this.communityStepperModel.resetImage('logo');
    this.cropperSnapshot = null;
    this.uploadingAbortCb?.();
  };
}

export interface IStep2Store extends IStepBase {
  readonly logo: CommunityImageType;
  cropperSnapshot: CropperSnapshot | null;
  // set's a logo based on the passed file
  setLogo: (file: File, originalFileUrl?: string) => void;
  setCropperSnapshot: (snapshot: CropperSnapshot) => void;
  // uploads an image to the BE
  triggerUploading: () => void;
}
