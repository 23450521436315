import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { MenuItem } from './MenuItem';
import { useSidebarMenu } from './useSidebarMenu';

import { paths } from '@mainApp/src/config';
import { useRouter } from 'next/router';
import { ScreenWrapper, SettingIdsEnum } from '../common';

const _SidebarMenu = () => {
  const router = useRouter();

  const {
    authStore,
    dimensionsStore: { isMobile },
    accountSettingsStore,
  } = useMultipleInjection([
    IOC_TOKENS.authStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.accountSettingsStore,
  ]);
  const { t } = useTranslation(['common', 'channel']);

  const { MENU_LIST } = useSidebarMenu();

  let content = (
    <div
      className={classNames(
        'pb-[24px] pt-[24px] ',
        isMobile
          ? 'bg-utilities-lightDark h-full w-full'
          : 'w-[216px] bg-surface-mapping-secondary-base'
      )}
    >
      <div className="flex flex-col gap-4">
        {MENU_LIST.map((menuProps) => {
          const { label, ...otherMenuProps } = menuProps;
          return (
            <MenuItem
              label={t<string>(label)}
              {...otherMenuProps}
              onSelect={(menuId) => {
                switch (menuId) {
                  case SettingIdsEnum.logOut:
                    authStore.logout();
                    accountSettingsStore.setShow(false);
                    router.push(paths.home);
                    break;
                  default:
                    accountSettingsStore.setActiveSettingId(menuId);
                    break;
                }
              }}
              active={accountSettingsStore.activeSettingId === menuProps.id}
              key={menuProps.id}
            />
          );
        })}
      </div>
    </div>
  );

  if (isMobile) {
    content = (
      <div className="flex w-full flex-col">
        <ScreenWrapper
          showBackBtn={false}
          title={t('channel:channelSettings.label')}
        >
          {content}
        </ScreenWrapper>
      </div>
    );
  }

  return content;
};

export const SidebarMenu = observer(_SidebarMenu);
