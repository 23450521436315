import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import _keyBy from 'lodash/keyBy';

import {
  GetCommunitiesQueryVariables,
  ICommunityStore,
} from './Community.store.types';

import {
  BlockedUsersPayloadEdge,
  CommunityEdge,
  CommunityRoleListOption,
  CommunityRolePayload,
  CommunityRolesObj,
  CreateCommunityInput,
  FeaturedCommunityPayload,
  NewestCommunityPayloadEdge,
  PaginatedData,
  Role_List_Types,
  UserEdge,
} from '@10x/foundation/types';

// TODO: DI Injection
import { UserModel } from './User.model';

import type {
  FEATURED_COMMUNITIES_SETTING_SORT_TYPE,
  FetchOptions,
  GetCommunityMembersParamsType,
  GetNewestCommunitiesParamsType,
  ICommunityRepository,
} from '../repositories/Community.repository.types';
import { BaseRepositoryResponse } from '../repositories/types';
import { CommunityModel } from './Community.model';

enableStaticRendering(typeof window === 'undefined');

import { inject, injectable } from 'inversify';
import { UseQueryState } from 'urql';
import { IOC_TOKENS } from '../ioc';
import type { IUserRepository } from '../repositories';
import { ApiBase, CommonApiDataShapeType, IterableDataShape } from './ApiBase';
import { NewestCommunityModel } from './NewestCommunity.model';
import type { IToastStore } from './Toast.store';
import type { IAuthStore, ISystemStore } from './types';
import { UniqueKeysRegistry } from './types';

@injectable()
export class CommunityStore extends ApiBase implements ICommunityStore {
  repository: ICommunityRepository;
  systemStore: ISystemStore;
  authStore: IAuthStore;
  userRepository: IUserRepository;

  members: CommonApiDataShapeType<IterableDataShape<UserModel> | null> =
    CommunityStore.generateCommonApiDataShape();

  blockedMembers: CommonApiDataShapeType<IterableDataShape<UserModel> | null> =
    CommunityStore.generateCommonApiDataShape();

  roles: CommonApiDataShapeType<CommunityRolesObj | null> =
    CommunityStore.generateCommonApiDataShape();
  featuredCommunities: CommonApiDataShapeType<CommunityModel[] | null> =
    CommunityStore.generateCommonApiDataShape();

  userCommunities: CommonApiDataShapeType<IterableDataShape<CommunityModel>> =
    CommunityStore.generateCommonApiDataShape(false, {
      registry: {},
      valuesArray: [],
    });

  newestCommunities: CommonApiDataShapeType<
    IterableDataShape<NewestCommunityModel>
  > = CommunityStore.generateCommonApiDataShape(false, {
    registry: {},
    valuesArray: [],
  });

  userCommunitiesPaginationVars: GetCommunitiesQueryVariables = {
    last: 15,
    before: '',
    excludeFeatured: false,
  };
  newestCommunitiesPaginationVars: GetCommunitiesQueryVariables = {
    last: 5,
    before: '',
  };

  activeCommunity: CommonApiDataShapeType<CommunityModel | null> =
    CommunityStore.generateCommonApiDataShape();

  creatingCommunity = false;

  get defaultChannel() {
    return this.activeCommunity.data?.serverData.defaultChannel;
  }

  get rolesList() {
    if (!this.roles?.data) {
      return [];
    }

    const roles = Object.values(this.roles.data);

    return roles as CommunityRolePayload[];
  }

  constructor(
    @inject(IOC_TOKENS.toastStore) toastStore: IToastStore,
    @inject(IOC_TOKENS.systemStore) systemStore: ISystemStore,
    @inject(IOC_TOKENS.authStore) authStore: IAuthStore,
    @inject(IOC_TOKENS.communityRepository) repository: ICommunityRepository,
    @inject(IOC_TOKENS.userRepository) userRepository: IUserRepository
  ) {
    super(toastStore);
    this.systemStore = systemStore;
    this.authStore = authStore;
    this.repository = repository;
    this.userRepository = userRepository;

    makeObservable(this, {
      defaultChannel: computed,
      featuredCommunities: observable,
      newestCommunities: observable,
      members: observable,
      blockedMembers: observable,
      roles: observable,
      activeCommunity: observable,
      userCommunities: observable,
      creatingCommunity: observable,
      userCommunitiesPaginationVars: observable,
      createCommunity: action,

      rolesList: computed,

      setUserCommunitiesPaginationVars: action,

      addCommunityRolesToMember: action,
      removeCommunityRolesToMember: action,
      hasOwnerRole: action,

      setUserCommunities: action,

      getFeaturedCommunities: flow,
      getNewestCommunities: flow,
      getCommunityRoles: flow,
      setActiveCommunity: action,
      resetActiveCommunity: action,
      getCommunityMembers: action,
      getAndSetActiveCommunity: action,

      resetMembers: action,
    });

    // TODO: maybe clean reaction?
    reaction(
      () => {
        return this.activeCommunity.data?.serverData.id as string;
      },
      (activeCommunityId: string) => {
        if (!activeCommunityId) return;
        this.getCommunityRoles(activeCommunityId);
      }
    );
  }

  setUserCommunitiesPaginationVars = (
    variables: GetCommunitiesQueryVariables
  ) => {
    this.userCommunitiesPaginationVars = variables;
  };
  createCommunity = async (payload: CreateCommunityInput) => {
    this.creatingCommunity = true;
    const res = await this.repository.createCommunity(payload);
    this.creatingCommunity = false;
    return res;
  };

  *getFeaturedCommunities(
    limit = 7,
    sortType: FEATURED_COMMUNITIES_SETTING_SORT_TYPE = 'RANDOM'
  ) {
    this.featuredCommunities = {
      ...this.featuredCommunities,
      loading: true,
    };

    const { data, error } = yield this.repository.getFeaturedCommunities(
      limit,
      sortType
    );

    if (error) {
      this.handleError('Featured Communities Error', error.message);
      return;
    }

    const result = data
      ? data.map((data: FeaturedCommunityPayload) => {
          const communityData = data?.community;
          return communityData
            ? new CommunityModel(communityData, this.systemStore)
            : CommunityModel.generateMockedCommunityModel(this.systemStore);
        })
      : [];

    this.featuredCommunities = {
      ...this.featuredCommunities,
      data: result,
      loading: false,
      error: error,
    };
  }
  *getNewestCommunities(params?: GetNewestCommunitiesParamsType) {
    this.newestCommunities = {
      ...this.newestCommunities,
      loading: true,
    };

    const finalParams = {
      ...this.newestCommunitiesPaginationVars,
      ...(params || {}),
    };

    const { data, error } = yield this.repository.getNewestCommunities(
      finalParams
    );

    if (error) {
      this.handleError('Newest Communities Error', error.message);
      return;
    }

    const edges = data?.edges;
    const pageInfo = data?.pageInfo;
    if (!edges) return;

    const registry = edges.reduce(
      (
        acc: UniqueKeysRegistry<NewestCommunityModel>,
        edge: NewestCommunityPayloadEdge
      ) => {
        const node = edge.node;

        const newestCommunityModel = new NewestCommunityModel(
          node,
          this.systemStore
        );
        acc[node.id] = newestCommunityModel;

        return acc;
      },
      { ...this.newestCommunities.data?.registry }
    );

    this.newestCommunities = {
      data: {
        registry: registry,
        valuesArray: Object.values(registry),
      },
      error: error,
      loading: false,
      meta: {
        // store variables just in case. Basically we need only pageInfo
        variables: data?.operation?.variables,
        pageInfo: pageInfo,
        nextPageLoading: false,
      },
    };

    // const result = data
    //   ? data.map((data: NewestCommunityPayload) => {
    //       const communityData = data?.community;
    //       return communityData
    //         ? new CommunityModel(communityData, this.systemStore)
    //         : CommunityModel.generateMockedCommunityModel(this.systemStore);
    //     })
    //   : [];

    // this.newestCommunities = {
    //   ...this.newestCommunities,
    //   data: result,
    //   loading: false,
    //   error: error,
    // };
  }

  fetchMoreNewestCommunities = async (
    params?: GetNewestCommunitiesParamsType
  ) => {
    const newestCommunities = this.newestCommunities;
    if (newestCommunities.loading) {
      return;
    }

    if (!newestCommunities.meta) {
      return;
    }

    const { pageInfo, variables } = newestCommunities.meta;

    if (!pageInfo || !pageInfo.hasPreviousPage) {
      return;
    }

    const options = {
      ...variables,
      before: pageInfo.startCursor,
      last: pageInfo.count,
      ...(params || {}),
    };

    this.getNewestCommunities(options);
  };

  resetMembers = () => {
    this.members = CommunityStore.generateCommonApiDataShape();
  };

  fetchMoreMembers = async (params?: GetCommunityMembersParamsType) => {
    const members = this.members;
    if (members.loading) {
      return;
    }

    if (!members.meta) {
      return;
    }

    const { pageInfo, variables } = members.meta;

    if (!pageInfo || !pageInfo.hasNextPage) {
      return;
    }

    const options = {
      ...variables,
      after: pageInfo.endCursor,
      first: pageInfo.count,
      ...(params || {}),
    };

    this.getCommunityMembers(options);
  };

  getCommunityMembers = async (
    params: GetCommunityMembersParamsType,
    options?: FetchOptions
  ) => {
    runInAction(() => {
      this.members = {
        ...this.members,
        loading: true,
      };
    });

    const response = await this.repository.getCommunityMembers(
      {
        ...params,
        first: params.first || 10,
      },
      options
    );
    const { error, data, originalResponse } = response;
    if (error) {
      this.handleError('Get Members', error.message);
      return;
    }

    const registry = data
      ? data.reduce(
          (acc: UniqueKeysRegistry<UserModel>, edge: UserEdge) => {
            const node = edge.node;

            acc[node.id] = new UserModel(node);

            return acc;
          },
          { ...(this.members.data?.registry || {}) }
        )
      : {};
    runInAction(() => {
      this.members = {
        data: {
          registry: registry,
          valuesArray: Object.values(registry),
        },
        error: error,
        loading: false,
        meta: {
          variables: originalResponse.operation.variables,
          pageInfo: originalResponse?.data?.communityMembers.pageInfo,
          nextPageLoading: false,
          totalCount: originalResponse?.data?.communityMembers.totalCount,
        },
      };
    });
  };

  getCommunityBlockedMembers = async (
    params: GetCommunityMembersParamsType,
    options?: FetchOptions
  ) => {
    runInAction(() => {
      this.blockedMembers = {
        ...this.blockedMembers,
        loading: true,
      };
    });

    const response = await this.repository.getCommunityBlockedMembers(
      {
        ...params,
        first: params.first || 5,
      },
      options
    );
    const { error, data, originalResponse } = response;
    if (error) {
      this.handleError('Get Blocked Members', error.message);
      return;
    }

    const registry = data
      ? data.reduce(
          (
            acc: UniqueKeysRegistry<UserModel>,
            edge: BlockedUsersPayloadEdge
          ) => {
            const node = edge.node;
            if (!acc[node.userId]) {
              acc[node.userId] = new UserModel({ ...node, id: node.userId });
            }

            return acc;
          },
          {}
        )
      : {};

    runInAction(() => {
      this.blockedMembers = {
        data: {
          registry: registry,
          valuesArray: Object.values(registry),
        },
        error: error,
        loading: false,
        meta: {
          variables: originalResponse.operation.variables,
          pageInfo: originalResponse?.data?.communityBlockedMembers.pageInfo,
          nextPageLoading: false,
          totalCount:
            originalResponse?.data?.communityBlockedMembers.totalCount,
        },
      };
    });
  };

  fetchMoreBlockedMembers = async (params?: GetCommunityMembersParamsType) => {
    const blockedMembers = this.blockedMembers;
    if (blockedMembers.loading) {
      return;
    }

    if (!blockedMembers.meta) {
      return;
    }

    const { pageInfo, variables } = blockedMembers.meta;

    if (!pageInfo || !pageInfo.hasNextPage) {
      return;
    }

    const options = {
      ...variables,
      before: pageInfo.startCursor,
      first: pageInfo.count,
      ...(params || {}),
    };

    this.getCommunityBlockedMembers(options);
  };

  *getCommunityRoles(
    communityId: string,
    options: CommunityRoleListOption = {
      listType: Role_List_Types.All,
    }
  ) {
    this.roles = {
      ...this.roles,
      loading: true,
    };

    const { data = [], error } = yield this.repository.getCommunityRoles(
      communityId,
      options
    );

    if (error) {
      this.handleError('Community Roles', error.message);
      return;
    }

    this.roles = {
      ...this.roles,
      //@ts-ignore
      data: _keyBy(data, (o) => o.name),
      loading: false,
      error: error,
    };
  }

  getAndSetActiveCommunity = async (communityId: string) => {
    const community = await this.getCommunity(communityId);
    this.setActiveCommunity(community);
  };

  setActiveCommunity = (community: CommunityModel | null) => {
    this.activeCommunity = {
      ...this.activeCommunity,
      data: community,
      loading: false,
    };
  };

  resetActiveCommunity = () => {
    this.activeCommunity = {
      ...this.activeCommunity,
      data: null,
      loading: false,
    };

    this.repository.unsubscribeGetCommunityBySlugName();
  };

  getCommunity = async (communityId: string) => {
    const { data, error } = await this.repository.getCommunity(communityId);

    if (error) {
      this.handleError('Get Community', error.message);
      return null;
    }

    return new CommunityModel(data, this.systemStore);
  };

  joinCommunity = async (communityId: string, slugName: string) => {
    const { error } = await this.repository.joinCommunity(
      communityId,
      slugName
    );

    if (error) {
      this.handleError('Join Community', error.message);
      return null;
    }
  };

  leaveCommunity = async (communityId: string, slugName: string) => {
    const { error } = await this.repository.leaveCommunity(
      communityId,
      slugName
    );

    if (error) {
      this.handleError('Leave Community', error.message);
      return null;
    }
  };

  getCommunityBySlugName = async (slugName: string) => {
    const { data, error } = await this.repository.getCommunityBySlugName(
      slugName
    );

    // TODO: custom error handler?
    if (error) {
      this.handleError('Community by slugname error', error.message);
      return null;
    }

    const res = data ? new CommunityModel(data, this.systemStore) : null;

    return res;
  };

  setSubscriptionCommunityBySlugOnce = (slugName: string) => {
    this.repository.subscribeToGetCommunityBySlugName(
      slugName,
      () => {
        runInAction(() => {
          this.activeCommunity = {
            ...this.activeCommunity,
            loading: true,
          };
        });
      },
      (response) => {
        const { data, error } = response;
        // TODO: custom error handler?
        if (error) {
          this.handleError('Community slugname subscriber', error.message);
          return null;
        }

        const community = data
          ? new CommunityModel(data, this.systemStore)
          : null;
        this.setActiveCommunity(community);
      }
    );
  };

  setUserCommunities = (
    queryData: UseQueryState<{
      userCommunities: PaginatedData<CommunityEdge>;
    }>
  ) => {
    if (!queryData) return;

    if (queryData.fetching) {
      this.userCommunities = {
        ...this.userCommunities,
        loading: true,
      };
      return;
    }
    const edges = queryData.data?.userCommunities?.edges;
    const pageInfo = queryData.data?.userCommunities?.pageInfo;

    if (!edges) return;

    const incomingValuesArray: CommunityModel[] = [];

    const incomingRegistry = edges.reduce(
      (acc: UniqueKeysRegistry<CommunityModel>, edge: CommunityEdge) => {
        const node = edge.node;
        const communityModel = new CommunityModel(node, this.systemStore);
        acc[node.id] = communityModel;
        incomingValuesArray.push(communityModel);

        return acc;
      },
      {}
    );
    this.userCommunities = {
      data: {
        registry: incomingRegistry,
        valuesArray: incomingValuesArray,
      },
      error: queryData.error,
      loading: false,
      meta: {
        // store variables just in case. Basically we need only pageInfo
        variables: queryData?.operation?.variables,
        pageInfo: pageInfo,
        nextPageLoading: false,
      },
    };
  };

  setSubscriptionToGetUserCommunitiesOnce(ownerId: string) {
    this.repository.subscribeToGetUserCommunities(
      ownerId,
      () => {
        runInAction(() => {
          this.userCommunities = {
            ...this.userCommunities,
            loading: true,
          };
        });
      },
      this.userCommunitiesSubscriber
    );
  }

  // TODO: can be deleted as using hook for this
  private userCommunitiesSubscriber = action(
    (response: BaseRepositoryResponse<CommunityEdge[]>) => {
      const { error, data, originalResponse } = response;

      if (error) {
        // theoretically it should be done in the urql error exchange
        // this.handleError('User communitites subscriber', error.message);
        return null;
      }

      const incomingRegistry = data
        ? data.reduce(
            (acc: UniqueKeysRegistry<CommunityModel>, edge: CommunityEdge) => {
              const node = edge.node;
              if (!acc[node.id]) {
                acc[node.id] = new CommunityModel(node, this.systemStore);
              }

              return acc;
            },
            {}
          )
        : {};

      const registry = {
        ...this.userCommunities.data?.registry,
        ...incomingRegistry,
      };

      this.userCommunities = {
        data: {
          registry: registry,
          valuesArray: Object.values(registry),
        },
        error: error,
        loading: false,
        meta: {
          variables: originalResponse.operation.variables,
          pageInfo: originalResponse?.data?.userCommunities.pageInfo,
          nextPageLoading: false,
        },
      };
    }
  );

  blockUser = async (communityId: string, userId: string) => {
    const { error } = await this.userRepository.blockUser(communityId, userId);

    if (error) {
      this.handleError('Error', error.message);
      return;
    }

    const membersRegistry = { ...this.members.data?.registry };

    delete membersRegistry[userId];

    runInAction(() => {
      this.members = {
        ...this.members,
        data: {
          registry: membersRegistry,
          valuesArray: Object.values(membersRegistry),
        },
        meta: {
          ...this.members.meta,
          // Decrement the total members to reflect on the TAB of members
          totalCount: this.members.meta.totalCount - 1,
        },
      };

      this.blockedMembers = {
        ...this.blockedMembers,
        meta: {
          ...this.blockedMembers.meta,
          // Increment the total of blocked members to reflect on the TAB of banned members
          totalCount: this.blockedMembers.meta.totalCount + 1,
        },
      };
    });
  };

  unblockUser = async (communityId: string, userId: string) => {
    const { error } = await this.userRepository.unblockUser(
      communityId,
      userId
    );

    if (error) {
      this.handleError('Error', error.message);
      return;
    }

    const blockedMembersRegistry = {
      ...this.blockedMembers.data?.registry,
    };

    delete blockedMembersRegistry[userId];

    runInAction(() => {
      this.blockedMembers = {
        ...this.blockedMembers,
        data: {
          registry: blockedMembersRegistry,
          valuesArray: Object.values(blockedMembersRegistry),
        },
        meta: {
          ...this.blockedMembers.meta,
          totalCount: this.blockedMembers.meta.totalCount - 1,
        },
      };

      this.members = {
        ...this.members,
        meta: {
          ...this.members.meta,
          totalCount: this.members.meta.totalCount + 1,
        },
      };
    });
  };

  removeCommunityRolesToMember = async (
    communityId: string,
    roleIds: string[],
    userId: string,
    callbackErr?: () => void
  ) => {
    const { error } = await this.repository.removeCommunityRolesToMember(
      communityId,
      roleIds,
      userId
    );

    if (error && callbackErr) {
      callbackErr();
    }
  };

  addCommunityRolesToMember = async (
    communityId: string,
    roleIds: string[],
    userId: string,
    callbackErr?: () => void
  ) => {
    const { error } = await this.repository.addCommunityRolesToMember(
      communityId,
      roleIds,
      userId
    );

    if (error && callbackErr) {
      callbackErr();
    }
  };

  hasOwnerRole<T extends { name: string }>(roles: T[]) {
    return roles.some((role) => role.name.toLocaleLowerCase() === 'owner');
  }
}
