import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { StepPanel } from './StepPanel';

import { UploadImageContainer } from '@mainApp/src/components/common';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

export function _Step2() {
  const {
    steps: { step2 },
  } = useInjection(IOC_TOKENS.createCommunityStepperStore);

  const { t } = useTranslation(['common', 'create-community']);

  return (
    <StepPanel
      stepStore={step2}
      title={t('create-community:step2Title')}
      description={t('create-community:step2Description')}
    >
      <UploadImageContainer
        fileSrc={step2.logo.fileUrl}
        croppedFileSrc={step2.logo.croppedFileUrl}
        cropperSnapshot={step2.cropperSnapshot}
        setCropperSnapshot={step2.setCropperSnapshot}
        onFileCatch={(files: FileList, fileUrl: string) => {
          step2.setLogo(files[0], fileUrl);
        }}
        onFileCrop={step2.setLogo}
        onCancel={step2.reset}
        onSave={step2.triggerUploading}
        uploadingStatus={step2.logo.status}
        uploadingPercentage={step2.logo.uploadingPercent}
        error={step2.logo.error}
        fileName={step2.logo.fileName}
        fileSize={step2.logo.fileSize}
      />
    </StepPanel>
  );
}

export const Step2 = observer(_Step2);
