import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { FormFieldInput } from '@mainApp/src/components/common';
import { StepPanel } from './StepPanel';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

export function _Step3() {
  const {
    steps: { step3 },
  } = useInjection(IOC_TOKENS.createCommunityStepperStore);

  const { t } = useTranslation(['common', 'create-community']);

  return (
    <StepPanel
      title={t('create-community:step3Title')}
      description={t('create-community:step3Description')}
      stepStore={step3}
    >
      <FormFieldInput
        onChange={(val) => {
          step3.setCommunityName({
            value: val,
            error: step3.communityName.error,
          });
        }}
        onError={(error) => {
          step3.setCommunityName({
            value: step3.communityName.value,
            error: error,
          });
        }}
        maxCharsAtRight={true}
        value={step3.communityName.value}
        placeholder={t('create-community:step3TextInputLabel') as string}
        maxCharsLimit={25}
        inputName="communityName"
      />
      <FormFieldInput
        textAreaMode
        onError={(error) => {
          step3.setDescription({
            value: step3.description.value,
            error: error,
          });
        }}
        TextAreaProps={{
          style: {
            resize: 'none',
          },
          rows: 3,
        }}
        rootCn="h-[120px]"
        onChange={(val) => {
          step3.setDescription({
            value: val,
            error: step3.description.error,
          });
        }}
        maxCharsAtRight={true}
        value={step3.description.value}
        placeholder={t('create-community:step3DescriptionInputLabel') as string}
        maxCharsLimit={95}
        inputName="communityAbout"
      />
    </StepPanel>
  );
}

export const Step3 = observer(_Step3);
