import { classNames } from '@foundationPathAlias/utilities';

export type PrimaryButtonPropsType = {
  /** if true - should use the dynamic primary colors from the active community BE response */
  dynamicColorsMode?: boolean;
  cn?: string;
  children: React.ReactNode;
  /** 36px height btns specifically used in the top bars. It's a different type of button according the figma */
  middleMode?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export function PrimaryButton(props: PrimaryButtonPropsType) {
  const {
    cn,
    dynamicColorsMode = false,
    children,
    middleMode,
    disabled,
    onClick,
    ...otherProps
  } = props;

  return (
    <button
      className={classNames(
        'button-base',
        dynamicColorsMode ? 'primary-button-dynamic' : 'primary-button',
        disabled && 'opacity-40',
        middleMode && 'h-[36px] px-[20px] text-sm14T',
        cn
      )}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
}
