import { makeAutoObservable, reaction, runInAction } from 'mobx';
// TODO: Implement an onboarding model
import {
  CLOUDFLARE_VARIANTS_ENUM,
  getCloudflareSizeRecognition,
} from '@10x/foundation/src/utilities/getCloudflareSizeRecognition';
import { IOnboardingRepositoryInterface } from '@mainApp/src/repositories';
import { LoadingStatusEnum, TextFieldType } from '../types';
import {
  ColorType,
  CommunityColorType,
  CommunityColorVariants,
  CommunityImageType,
  TopicsType,
} from './CreateCommunityStepper.types';

const LOCALSTORAGE_KEY = 'communityStepperModel';

export const initialImageData: CommunityImageType = {
  status: LoadingStatusEnum.initial,
  file: null,
  fileUrl: null,
  croppedFileUrl: null,
  uploadedUrl: null,
  uploadingPercent: 0,
  errorMessage: null,
  fileName: null,
  fileSize: null,
  /** and image id that provides BE upload. Should use it
    to get the uploaded image on restoration the saved model in localstorage
  */
  id: null,
};

export const initialTopics: TopicsType = {
  topic1: {
    value: '',
    error: null,
  },
  topic2: {
    value: '',
    error: '',
  },
};

export const initialColor: CommunityColorType = {
  rgba: `rgba(101, 99, 255, 1)`,
  pureRgba: {
    r: 101,
    g: 99,
    b: 255,
    a: 1,
  },
  hex: '#6563FF',
  isSavedOnClient: false,
};

export class CommunityStepperModel {
  thumbnail: CommunityImageType = {
    ...initialImageData,
  };

  logo: CommunityImageType = {
    ...initialImageData,
  };

  communityName: TextFieldType = {
    value: '',
    error: null,
  };

  description: TextFieldType = {
    value: '',
    error: null,
  };

  topics: TopicsType = { ...initialTopics };

  lmPrimaryColor: CommunityColorType | null = null;
  dmPrimaryColor: CommunityColorType | null = null;

  /**
   * if community was created so set this community slug
   * because the user shouldn't be able to create
   * a new community anymore and this slug will be used
   * to redirect the user to the created community. Also
   * it is used in the usecase when a guest creates a community
   * and after that logs in into app. After redirections
   * the community will be automatically created and this token
   * will be set to true. In future this token will be used
   * to see if the community was created or not to prevent
   * duplication action on every login/logout. Community model
   * contains in memory so it will happen definitely
   */
  createdCommunitySlug = '';
  completed = false;

  reactionDisposer;

  repository: IOnboardingRepositoryInterface;

  get isValidCommunityName() {
    const { value, error } = this.communityName;
    return Boolean(value) && !error;
  }

  get isValidCommunityDescription() {
    const { value, error } = this.description;
    return Boolean(value) && !error;
  }

  constructor(repository: IOnboardingRepositoryInterface) {
    this.repository = repository;
    makeAutoObservable(this);

    this.tryToRestore();

    this.reactionDisposer = reaction(
      () => this.asJson, // Observe everything that is used in the JSON.
      (json) => {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(json));
      }
    );
  }

  // for the temporary storing in localStorage
  get asJson() {
    return {
      thumbnail: this.thumbnail,
      logo: this.logo,
      communityName: this.communityName,
      description: this.description,
      topics: this.topics,
      lmPrimaryColor: this.lmPrimaryColor,
      dmPrimaryColor: this.dmPrimaryColor,
      createdCommunitySlug: this.createdCommunitySlug,
      completed: this.completed,
    };
  }

  // for BE integration - create community
  get backendPayload() {
    return {
      name: this.communityName.value,
      description: this.description.value,
      topics: Object.values(this.topics)
        // don't want to have the empty string in payload
        .filter((o) => Boolean(o.value))
        .map((o) => o.value),
      thumbnail: this.thumbnail.id,
      logo: this.logo.id,
      color: {
        light: this.lmPrimaryColor?.pureRgba,
        dark: this.dmPrimaryColor?.pureRgba,
      },
    };
  }

  tryToRestore = () => {
    if (typeof window === 'undefined') return;

    const savedModel = localStorage?.getItem(LOCALSTORAGE_KEY);
    if (!savedModel) return;
    const { thumbnail, logo, ...modelJson } = JSON.parse(savedModel);

    Object.keys(modelJson).forEach((key) => {
      // @ts-ignore
      this[key] = modelJson[key];
    });
    if (thumbnail.id) {
      this.thumbnail = {
        ...thumbnail,
        status: LoadingStatusEnum.success,
      };
    }
    if (logo.id) {
      this.logo = {
        ...logo,
        status: LoadingStatusEnum.success,
      };
    }

    this.proceedRestoredImage('thumbnail', thumbnail);
    this.proceedRestoredImage('logo', logo, CLOUDFLARE_VARIANTS_ENUM.MEDIUM);
  };

  setImage = (type: CommunityColorVariants, data = {}) => {
    this[type] = {
      ...this[type],
      ...data,
    };
  };

  resetImage = (type: CommunityColorVariants) => {
    this[type] = {
      ...initialImageData,
    };
  };

  setCommunityName = (val: TextFieldType) => {
    this.communityName = val;
  };
  setDescription = (val: TextFieldType) => {
    this.description = val;
  };

  setCreatedCommunitySlug = (createdCommunitySlug: string) => {
    this.createdCommunitySlug = createdCommunitySlug;
  };
  setCompleted = (completed: boolean) => {
    this.completed = completed;
  };

  resetCommunityName = () => {
    this.setCommunityName({
      value: '',
      error: null,
    });
  };
  resetDescription = () => {
    this.setDescription({
      value: '',
      error: null,
    });
  };

  setTopics = (topics: { topic1: TextFieldType; topic2: TextFieldType }) => {
    this.topics = topics;
  };
  resetTopics = () => {
    this.setTopics({ ...initialTopics });
  };

  setColor = (type: 'dm' | 'lm', val: ColorType) => {
    const key: 'lmPrimaryColor' | 'dmPrimaryColor' = `${type}PrimaryColor`;
    this[key] = {
      ...val,
      hex: val.hex.toUpperCase(),
      // should be updated by the action
      isSavedOnClient: Boolean(this[key]?.isSavedOnClient),
    };
  };

  setIsColorSaved = (type: 'dm' | 'lm', isSaved: boolean) => {
    const key: 'lmPrimaryColor' | 'dmPrimaryColor' = `${type}PrimaryColor`;
    this[key] = {
      ...(this[key] as CommunityColorType),
      isSavedOnClient: isSaved,
    };
  };

  setDefaultContrastColors = (type: 'dm' | 'lm') => {
    const whiteForDm = {
      rgba: `rgba(255, 255, 255, 1)`,
      pureRgba: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      hex: '#FFFFFF',
    };
    const blackForLm = {
      rgba: `rgba(0, 0, 0, 1)`,
      pureRgba: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      hex: '#000000',
    };

    if (type === 'lm') {
      this.setColor('lm', blackForLm);
    } else {
      this.setColor('dm', whiteForDm);
    }
  };

  /**
   *
   * @param forceReset - if true the system will reset the colors to the initial state even if they were saved on the client
   */
  resetColors = (forceReset = false) => {
    if (forceReset || !this.lmPrimaryColor?.isSavedOnClient) {
      this.lmPrimaryColor = { ...initialColor };
    }

    if (forceReset || !this.dmPrimaryColor?.isSavedOnClient) {
      this.dmPrimaryColor = { ...initialColor };
    }
  };

  // Clean up the observer.
  dispose() {
    this.reactionDisposer();
  }

  getServerLoadedUrl = async (type: CommunityColorVariants, id: string) => {
    const imageUrls = await this.repository.getCommunityImage(type, id);
    return getCloudflareSizeRecognition(
      imageUrls,
      CLOUDFLARE_VARIANTS_ENUM.MEDIUM
    );
  };

  proceedRestoredImage = async (
    type: CommunityColorVariants,
    data: any,
    prefferedSize?: CLOUDFLARE_VARIANTS_ENUM
  ) => {
    // we have the real cloudflare data so can use it
    if (data.uploadedUrl) {
      runInAction(() => {
        this[type] = {
          ...data,
          croppedFileUrl: data.uploadedUrl,
          fileUrl: data.uploadedUrl,
          status: LoadingStatusEnum.success,
        };
      });
      return;
    }

    const id = data.id;
    if (id) {
      const imageUrls = await this.repository.getCommunityImage(type, id);
      const imageUrl = getCloudflareSizeRecognition(imageUrls, prefferedSize);

      runInAction(() => {
        this[type] = {
          ...data,
          croppedFileUrl: imageUrl,
          fileUrl: imageUrl,
          status: LoadingStatusEnum.success,
        };
      });
    }
  };

  reset = () => {
    this.thumbnail = {
      ...initialImageData,
    };
    this.logo = {
      ...initialImageData,
    };
    this.resetCommunityName();
    this.resetDescription();
    this.setTopics({ ...initialTopics });
    this.lmPrimaryColor = null;
    this.dmPrimaryColor = null;
    this.createdCommunitySlug = '';
    this.completed = false;
    localStorage?.removeItem(LOCALSTORAGE_KEY);
  };
}
