import { isFunction } from '@10x/foundation/src/utilities';
import type { IAuthStore, ICommunityStore } from '@mainApp/src/stores';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import { CommunityStepperModel } from './CommunityStepper.model';

import { CreateCommunityInput } from '@10x/foundation/types/graphql-schema';
import type { IOnboardingRepositoryInterface } from '@mainApp/src/repositories';

import { IStep1Store, Step1Store } from './Step1.store';
import { IStep2Store, Step2Store } from './Step2.store';
import { IStep3Store, Step3Store } from './Step3.store';
import { IStep4Store, Step4Store } from './Step4.store';
import { IStep5Store, Step5Store } from './Step5.store';

export type StepsType = {
  step1: IStep1Store;
  step2: IStep2Store;
  step3: IStep3Store;
  step4: IStep4Store;
  step5: IStep5Store;
};

export type OnboardingOnCompleteCb = (
  payload: CreateCommunityInput
  // TODO: Community type
) => void | null | Promise<any>;

export type StepKey = `step${1 | 2 | 3 | 4 | 5}`;

import { IOC_TOKENS } from '@mainApp/src/ioc';
import { inject, injectable } from 'inversify';

enableStaticRendering(typeof window === 'undefined');

@injectable()
export class CreateCommunityStepperStore
  implements ICreateCommunityStepperStore
{
  repository: IOnboardingRepositoryInterface;
  authStore: IAuthStore;

  isModalOpened = false;

  communityStepperModel: CommunityStepperModel;

  // it will be set at the init stage
  steps: StepsType = {
    step1: null,
    step2: null,
    step3: null,
    step4: null,
    step5: null,
  } as any;

  // it will be set at the init stage
  activeStepStore: StepsType[keyof StepsType] = null as any;

  onCompleteCallback: OnboardingOnCompleteCb | null = null;

  get createdCommunitySlug() {
    return this.communityStepperModel.createdCommunitySlug;
  }
  get completed() {
    return this.communityStepperModel.completed;
  }

  get isCommunityCreated() {
    return Boolean(this.createdCommunitySlug);
  }

  get isAllStepsReady() {
    return Object.keys(this.steps)
      .map((key) => this.steps[key as StepKey])
      .every((o) => Boolean(o?.canMoveNext));
  }
  get activeStepStoreKey() {
    return `step${this.activeStepStore.id}` as StepKey;
  }

  get canMoveBack() {
    return this.activeStepStore.id > 1;
  }

  constructor(
    @inject(IOC_TOKENS.onboardingRepository)
    repository: IOnboardingRepositoryInterface,
    @inject(IOC_TOKENS.communityStore)
    communityStore: ICommunityStore,
    @inject(IOC_TOKENS.authStore) authStore: IAuthStore
  ) {
    this.repository = repository;
    this.authStore = authStore;
    this.communityStepperModel = new CommunityStepperModel(repository);

    this.registerSteps({
      step1: new Step1Store(repository, this.communityStepperModel),
      step2: new Step2Store(repository, this.communityStepperModel),
      step3: new Step3Store(repository, this.communityStepperModel),
      step4: new Step4Store(repository, this.communityStepperModel),
      step5: new Step5Store(repository, this.communityStepperModel),
    });

    // probably could do it with reactions
    this.onCompleteCallback = communityStore.createCommunity;

    makeObservable(this, {
      isModalOpened: observable,
      communityStepperModel: observable,
      activeStepStore: observable,
      isAllStepsReady: computed,
      activeStepStoreKey: computed,
      registerSteps: action,
      proceedCommunityCreation: action,
      setActiveStepStoreById: action,
      reset: action,
    });

    // TODO: clean reaction? but it's a singletone so why?
    reaction(
      () => this.authStore.logged,
      (logged: boolean) => {
        if (
          logged &&
          this.isAllStepsReady &&
          this.completed &&
          // should create a community only if haven't done it before
          !this.communityStepperModel.createdCommunitySlug
        ) {
          // check if onboarding model exists and completed
          this.proceedCommunityCreation(true);
        }
      }
    );
  }

  setCompletedStatus = (val: boolean) => {
    this.communityStepperModel.setCompleted(val);
  };

  registerSteps = (steps: StepsType) => {
    this.steps = steps;
    steps.step1.setActive(true);
    this.setActiveStepStoreById(1);
    return this;
  };

  setActiveStepStoreById = (id: number) => {
    // should show the complete UI. // all steps ready means everything had been restored from the storage
    if (id > 5 && this.isAllStepsReady) {
      this.activeStepStore = this.steps.step5;
      this.activeStepStore.setActive(true);
      this.communityStepperModel.setCompleted(true);
      return;
    }
    const stepKey = `step${id}` as StepKey;
    this.activeStepStore = this.steps[stepKey];
    this.activeStepStore.setActive(true);
  };

  proceedCommunityCreation = async (continueAfterAuth?: boolean) => {
    if (isFunction(this.onCompleteCallback)) {
      const payload = this.communityStepperModel.backendPayload;

      const { data } = await this.onCompleteCallback?.(payload as any);
      if (!data) return false;
      this.communityStepperModel.setCreatedCommunitySlug(
        data.slugName || data.slug
      );

      if (continueAfterAuth) {
        this.redirectToCreatedCommunity();
      }
    } else {
      throw new Error(
        'Please, check the CreateCommunityStepperStore.onCompleteCallback. It must be defined'
      );
    }

    // to make a promise possible chain
    return true;
  };

  redirectToCreatedCommunity = () => {
    window.open(`${document?.location?.origin}/${this.createdCommunitySlug}`);
  };

  backToPrevStep = () => {
    const prevStepId = this.activeStepStore.id - 1;
    if (prevStepId < 1) return;

    this.setActiveStepStoreById(prevStepId);
  };

  setModal = (open: boolean) => {
    runInAction(() => {
      this.isModalOpened = open;
    });
  };
  reset = () => {
    this.activeStepStore = this.steps.step1;
    this.communityStepperModel.reset();
  };
}

export interface ICreateCommunityStepperStore {
  authStore: IAuthStore;
  repository: IOnboardingRepositoryInterface;
  communityStepperModel: CommunityStepperModel;
  steps: StepsType;
  activeStepStore: StepsType[keyof StepsType];
  createdCommunitySlug: string;
  isCommunityCreated: boolean;
  isModalOpened: boolean;

  readonly completed: boolean;
  readonly canMoveBack: boolean;
  readonly isAllStepsReady: boolean;
  readonly activeStepStoreKey: StepKey;

  onCompleteCallback: OnboardingOnCompleteCb | null;
  registerSteps(steps: StepsType): ICreateCommunityStepperStore;
  setActiveStepStoreById: (id: number) => void;
  redirectToCreatedCommunity: () => void;
  backToPrevStep: () => void;
  setCompletedStatus: (val: boolean) => void;
  // trigger a community creation
  proceedCommunityCreation: (continueAfterAuth?: boolean) => Promise<boolean>;
  setModal: (open: boolean) => void;
  reset: () => void;
}
