import { animated, useTransition } from '@react-spring/web';
import { ReactNode } from 'react';
import { animationConfig } from './animationConfig';

type Props = {
  showA: boolean;
  componentA: ReactNode;
  componentB: ReactNode;
  cn?: string;
  animationConfig?: typeof animationConfig;
};

export function AnimatedComponentsSwitcher(props: Props) {
  const {
    showA,
    componentA,
    componentB,
    cn,
    animationConfig: outerAnimConfig,
  } = props;
  const finalAnimationConfig = outerAnimConfig || animationConfig;
  const transitions = useTransition(showA, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: finalAnimationConfig.duration,
      easing: finalAnimationConfig.easings.easeInCubic,
    },
  });

  return (
    <>
      {transitions((style, show) => {
        const content = show ? componentA : componentB;
        return (
          <animated.div style={style} className={cn}>
            {content}
          </animated.div>
        );
      })}
    </>
  );
}
