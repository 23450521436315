import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';
import { CompletedUI } from '../CompletedUI';

export function useShowCompleteUIMobile() {
  const { interactiveStore, createCommunityStepperStore } =
    useMultipleInjection([
      IOC_TOKENS.interactiveStore,
      IOC_TOKENS.createCommunityStepperStore,
    ]);

  const showCompleteUIMobile = () => {
    createCommunityStepperStore.setModal(false);
    createCommunityStepperStore.setCompletedStatus(true);
    interactiveStore.setContent(
      <div className="mb-[24px] mt-[22px] px-[16px]">
        <CompletedUI />
      </div>
    );
    interactiveStore.setActiveContentType(
      InteractiveContentTypesEnum.OTHER,
      true
    );

    interactiveStore.setInteractiveElementOpen(true);
  };

  return showCompleteUIMobile;
}
