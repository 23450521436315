import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { animationConfig } from '@foundationPathAlias/components';
import { UploadImageContainer } from '@mainApp/src/components/common';
import { useMobileCtx } from '@mainApp/src/components/common/create-community-stepper/data';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { StepPanel } from './StepPanel';

export function _Step1Mobile() {
  const {
    steps: { step1 },
  } = useInjection(IOC_TOKENS.createCommunityStepperStore);

  const { openBottomSheetInFullMode, setIsBottomSheetIfFullWindowMode } =
    useMobileCtx();

  const { t } = useTranslation(['common', 'create-community']);

  return (
    <StepPanel
      stepStore={step1}
      title={t('create-community:step1Title')}
      description={t('create-community:step1Description')}
    >
      <UploadImageContainer
        cropperSnapshot={step1.cropperSnapshot}
        fileSrc={step1.thumbnail.fileUrl}
        croppedFileSrc={step1.thumbnail.croppedFileUrl}
        onFileCatch={(files: FileList, fileUrl: string) => {
          openBottomSheetInFullMode();
          setIsBottomSheetIfFullWindowMode(true);
          // do everything smoothly after the animation transition
          setTimeout(() => {
            step1.setThumbnail(files[0], fileUrl);
          }, animationConfig.duration);
        }}
        onFileCrop={step1.setThumbnail}
        setCropperSnapshot={step1.setCropperSnapshot}
        onCancel={step1.reset}
        onSave={step1.triggerUploading}
        uploadingStatus={step1.thumbnail.status}
        uploadingPercentage={step1.thumbnail.uploadingPercent}
        error={step1.thumbnail.error}
        fileName={step1.thumbnail.fileName}
        fileSize={step1.thumbnail.fileSize}
        hideCropperActions={true}
      />
    </StepPanel>
  );
}

export const Step1Mobile = observer(_Step1Mobile);
