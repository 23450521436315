// @ts-nocheck
// TODO: missed types for react-color lib
import { CustomPicker as CustomPickerOuter } from 'react-color';
import {
  // Alpha,
  EditableInput,
  Hue,
  Saturation,
} from 'react-color/lib/components/common';

import { isValidHex } from 'react-color/lib/helpers/color';

import { classNames } from '@foundationPathAlias/utilities';

export type ColorResult = {
  hex: string;
  hsl: HSL;
  hsv: HSV;
  rgb: RGB;
  source: 'rgb' | 'hsv' | 'hsl' | 'hue';
};
export type HSL = {
  h: number;
  s: number;
  l: number;
  a: number;
};
export type HSV = {
  h: number;
  s: number;
  v: number;
  a: number;
};
export type RGB = {
  r: number;
  g: number;
  b: number;
  a: number;
};

export const Pointer = () => {
  return (
    <div
      className="h-[8px] 
      w-[8px] rounded-full border-[2px] border-background-primary 
    "
    />
  );
};

function getFinalProps(
  colorValue: string | ColorResult,
  props: ColorPickerPropsType,
  names: (keyof ColorResult)[]
): ColorResult {
  if (typeof colorValue === 'string') {
    return props;
  }

  return names.reduce((acc, name) => {
    acc[name] = colorValue[name];
    return acc;
  }, {} as any);
}

export type ColorPickerOnChangeType = (
  data: ColorResult,
  event: React.ChangeEvent<any>
) => void;

export type ColorPickerPropsType = ColorResult & {
  color: string | ColorResult;
  handleChangeComplete?: ColorPickerOnChangeType;
  onChange: ColorPickerOnChangeType;
  oldHue?: number;
  Pointer?: JSX.Element;
  /** the slider pointer */
  HuePointer?: JSX.Element;
  rootCn?: string;
  saturationWrapperCn?: string;
  hueWrapperCn?: string;
  alphaWrapperCn?: string;
  inputWrapperCn?: string;
  showInput?: boolean;
};

export function _ColorPicker(props: ColorPickerPropsType) {
  const {
    color,
    Pointer: PropsPointer,
    HuePointer: PropsHuePointer,
    rootCn,
    saturationWrapperCn,
    hueWrapperCn,
    alphaWrapperCn,
    inputWrapperCn,
    showInput,
  } = props;

  const finalColorValues = getFinalProps(color, props, [
    'rgb',
    'hsl',
    'hsv',
    'hex',
  ]);

  const inputStyles = {
    width: '100%',
    paddingTop: 10,
    paddingRight: 12,
    paddingBottom: 10,
    paddingLeft: 12,
  };

  const FinalPoint = PropsPointer || Pointer;
  const HuePointer = PropsHuePointer || Pointer;

  return (
    <div
      className={classNames(
        'fnd-color-picker w-[264px] rounded-[8px] bg-background-primary p-[16px] shadow-element-shadow dark:bg-background-secondary-dark dark:shadow-element-shadow-dark ',
        rootCn
      )}
    >
      <div
        className={classNames(
          'relative h-[255px] w-[232px] overflow-hidden rounded-[4px]',
          saturationWrapperCn
        )}
      >
        <Saturation {...props} {...finalColorValues} pointer={FinalPoint} />
      </div>
      <div
        className={classNames(
          'relative mt-[16px] h-[8px] w-full overflow-hidden rounded-[100px]',
          hueWrapperCn
        )}
      >
        <Hue {...props} {...finalColorValues} pointer={HuePointer} />
      </div>
      {showInput && (
        <div
          className={classNames(
            'relative mt-[16px] flex w-full justify-between space-x-[4px] [&>*]:w-[100%] [&>*]:overflow-hidden [&>*]:rounded-[4px] [&>*]:border-[1px] [&>*]:border-element-normal dark:[&>*]:border-element-normal-dark',
            inputWrapperCn
          )}
        >
          <EditableInput
            {...props}
            style={{
              input: inputStyles,
            }}
            value={finalColorValues.hex}
            onChange={(data: string, event: React.ChangeEvent<any>) => {
              isValidHex(data) &&
                props.onChange(
                  {
                    hex: data,
                    source: 'hex',
                  } as any,
                  event
                );
            }}
          />
        </div>
      )}
    </div>
  );
}

export const ColorPicker = CustomPickerOuter(_ColorPicker);
