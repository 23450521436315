import { animated, useTransition } from '@react-spring/web';

import { animationConfig } from './animationConfig';

type Props = {
  show: boolean;
  children: React.ReactNode;
  cn?: string;
  animationConfig?: typeof animationConfig;
  position?:
    | 'absolute'
    | 'fixed'
    | 'relative'
    | 'static'
    | 'sticky'
    | 'inherit'
    | 'initial'
    | 'unset';
};

export function AnimatedFadeWrapper(props: Props) {
  const {
    show,
    children,
    cn,
    // absolute is default for the create community onboarding bottomsheet crop image header
    position = 'absolute',
    animationConfig: outerAnimationConfig,
  } = props;
  const finalAnimationConfig = outerAnimationConfig || animationConfig;
  const transitions = useTransition(show, {
    from: { opacity: 0, width: '100%', position: position },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: finalAnimationConfig.duration,
      easing: finalAnimationConfig.easings.easeInCubic,
    },
  });

  return (
    <>
      {transitions((style, item) =>
        item ? (
          <animated.div
            className={cn}
            style={{ ...style, width: '100%' } as any}
          >
            {children}
          </animated.div>
        ) : null
      )}
    </>
  );
}
