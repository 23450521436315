import { observer } from 'mobx-react-lite';

import { useGetActiveStepComponent } from './steps/useGetActiveStepComponent';

import { StepNumber } from '../shared/StepNumber';

export const _StepsColumn = () => {
  const ActiveStepComponent = useGetActiveStepComponent();

  return (
    <div className="flex flex-col px-[16px] pt-[16px]">
      <StepNumber />
      <ActiveStepComponent />
    </div>
  );
};

export const StepsColumn = observer(_StepsColumn);

/** 
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { StepPanel } from './StepPanel';

import {
  CommunityColors,
  FormFieldInput,
  UploadImageContainer,
} from '@mainApp/src/components/common';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

export const _StepsColumn = () => {
  const createCommunityStepperStore = useInjection(
    IOC_TOKENS.createCommunityStepperStore
  );
  const {
    steps: { step1, step2, step3, step4, step5 },
  } = createCommunityStepperStore;

  const { t } = useTranslation(['common', 'create-community']);

  const communityColorsErrorMessages = {
    invalidHexFormatError: t('create-community:invalidHexFormatError'),
    tooLightColorError: t('create-community:tooLightColorError'),
    tooDarkColorError: t('create-community:tooDarkColorError'),
    useAlternativeColorLm: t('create-community:useAlternativeColorLm'),
    useAlternativeColorDm: t('create-community:useAlternativeColorDm'),
  };

  return (
    <div className="space-y-[20px] pt-6">
      <StepPanel
        stepStore={step1}
        label={t('create-community:step1Label')}
        title={t('create-community:step1Title')}
        description={t('create-community:step1Description')}
      >
        <UploadImageContainer
          label={t('create-community:step1UploadImageContainerLabel')}
          cropperSnapshot={step1.cropperSnapshot}
          fileSrc={step1.thumbnail.fileUrl}
          onFileCatch={(files: FileList, fileUrl: string) => {
            step1.setThumbnail(files[0], fileUrl);
          }}
          onFileCrop={step1.setThumbnail}
          setCropperSnapshot={step1.setCropperSnapshot}
          onCancel={step1.reset}
          onSave={step1.triggerUploading}
          uploadingStatus={step1.thumbnail.status}
          uploadingPercentage={step1.thumbnail.uploadingPercent}
          error={step1.thumbnail.error}
          fileName={step1.thumbnail.fileName}
          fileSize={step1.thumbnail.fileSize}
        />
      </StepPanel>

      <StepPanel
        stepStore={step2}
        label={t('create-community:step2Label')}
        title={t('create-community:step2Title')}
        description={t('create-community:step2Description')}
      >
        <UploadImageContainer
          label={t('create-community:step2UploadImageContainerLabel')}
          fileSrc={step2.logo.fileUrl}
          cropperSnapshot={step2.cropperSnapshot}
          setCropperSnapshot={step2.setCropperSnapshot}
          onFileCatch={(files: FileList, fileUrl: string) => {
            step2.setLogo(files[0], fileUrl);
          }}
          onFileCrop={step2.setLogo}
          onCancel={step2.reset}
          onSave={step2.triggerUploading}
          uploadingStatus={step2.logo.status}
          uploadingPercentage={step2.logo.uploadingPercent}
          error={step2.logo.error}
          fileName={step2.logo.fileName}
          fileSize={step2.logo.fileSize}
        />
      </StepPanel>
      <StepPanel
        label={t('create-community:step3Label')}
        title={t('create-community:step3Title')}
        description={t('create-community:step3Description')}
        stepStore={step3}
      >
        <FormFieldInput
          onChange={(val) => {
            step3.setCommunityName({
              value: val,
              error: step3.communityName.error,
            });
          }}
          onError={(error) => {
            step3.setCommunityName({
              value: step3.communityName.value,
              error: error,
            });
          }}
          value={step3.communityName.value}
          label={t('create-community:step3TextInputLabel')}
          placeholder={t('create-community:step3TextInputLabel') as string}
          maxCharsLimit={25}
          inputName="communityName"
        />
        <FormFieldInput
          textAreaMode
          onError={(error) => {
            step3.setDescription({
              value: step3.description.value,
              error: error,
            });
          }}
          TextAreaProps={{
            style: {
              resize: 'none',
            },
            rows: 3,
            // maxLength: 95,
          }}
          rootCn="h-[120px]"
          onChange={(val) => {
            step3.setDescription({
              value: val,
              error: step3.description.error,
            });
          }}
          value={step3.description.value}
          label={t('common:about.label')}
          placeholder={
            t('create-community:step3DescriptionInputLabel') as string
          }
          maxCharsLimit={95}
          inputName="communityAbout"
        />
      </StepPanel>
      <StepPanel
        label={t('create-community:step4Label')}
        title={t('create-community:step4Title')}
        description={t('create-community:step4Description')}
        stepStore={step4}
      >
        <FormFieldInput
          onChange={(val) => {
            step4.setTopicValue(1, {
              value: val,
              error: step4.topics.topic1.error,
            });
          }}
          onError={(error) => {
            step4.setTopicValue(1, {
              value: step4.topics.topic1.value,
              error: error,
            });
          }}
          value={step4.topics.topic1.value}
          label={t('create-community:step4Topic1Label')}
          maxCharsLimit={12}
          inputName="communityTopic1"
          placeholder={t('create-community:step4Topic1Placeholder') as string}
        />
        <FormFieldInput
          onChange={(val) => {
            step4.setTopicValue(2, {
              value: val,
              error: step4.topics.topic2.error,
            });
          }}
          onError={(error) => {
            step4.setTopicValue(2, {
              value: step4.topics.topic2.value,
              error: error,
            });
          }}
          value={step4.topics.topic2.value}
          label={t('create-community:step4Topic2Label')}
          maxCharsLimit={12}
          inputName="communityTopic2"
          placeholder={t('create-community:step4Topic2Placeholder') as string}
        />
      </StepPanel>
      <StepPanel
        label={t('create-community:step5Label')}
        title={t('create-community:step5Title')}
        description={t('create-community:step5Description')}
        stepStore={step5}
      >
        <CommunityColors
          initialPrimaryColor={step5.initialPrimaryColor}
          setColor={step5.setColor}
          lmLabel={t('create-community:communityLmColorTitle')}
          lmPrimaryColor={step5.lmPrimaryColor}
          setLmPrimaryBlack={step5.setLmPrimaryBlack}
          dmLabel={t('create-community:communityDmColorTitle')}
          dmPrimaryColor={step5.dmPrimaryColor}
          setDmPrimaryWhite={step5.setDmPrimaryWhite}
          errorMessages={communityColorsErrorMessages}
        />
      </StepPanel>
    </div>
  );
};

export const StepsColumn = observer(_StepsColumn);
*/
