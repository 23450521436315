import { DoubleLinListNode } from '@foundationPathAlias/data/DoubleLinListNode';
import { DoubleLinListRegistryService } from '@foundationPathAlias/data/DoubleLinListRegistry.service';
import { createContext, ReactNode, useRef, useState } from 'react';

export type LevelDataType = {
  el: HTMLHRElement | null;
  orderNumberId: number;
  predefinedLevelVal?: number | null;
  levelVal: number;
  // if defied, it means that this level is the last one and the bottomsheet shouldn't be closed further
  lastLevelToClose?: boolean;
};

export enum StatusEnum {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
  COLLAPSED = 'COLLAPSED',
}

type DirectionType = 'Top' | 'Bottom';

type InitialStateType = {
  currentCollapseLevelNode: DoubleLinListNode<LevelDataType> | null;
  status: StatusEnum;
};

const initialState: InitialStateType = {
  currentCollapseLevelNode: null,
  status: StatusEnum.CLOSED,
};

type BottomSheetCtxType = InitialStateType & {
  addCollapseLevelData: (data: LevelDataType) => void;
  getNextCollapseLevelData: (
    directionOuter?: DirectionType
  ) => LevelDataType | null;
  getCurrentCollapseLevelData: () => LevelDataType | null;
  getPrevCollapseLevelData: (
    directionOuter?: DirectionType
  ) => LevelDataType | null;
  moveToNextCollapseLevel: (direction?: DirectionType) => LevelDataType | null;
  moveToPrevCollapseLevel: (direction?: DirectionType) => LevelDataType | null;
  reset: () => void;
};

export const BottomSheetCtx = createContext<BottomSheetCtxType | undefined>(
  undefined
);

export const BottomSheetCtxProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, setState] = useState(initialState);
  const registryRef = useRef(new DoubleLinListRegistryService<LevelDataType>());

  const getRegistry = () => registryRef.current;

  const { currentCollapseLevelNode, status } = state;

  const addCollapseLevelData = (data: LevelDataType) => {
    const registry = getRegistry();
    const node = new DoubleLinListNode(data, String(data.orderNumberId));

    registry.addNode(node);
  };

  // to avoid some possible issue with old ref in callbacks etc.
  const getCurrentCollapseLevelData = () =>
    currentCollapseLevelNode?.model || null;

  const getNextCollapseLevelData = (directionOuter?: DirectionType) => {
    const direction = directionOuter || 'Bottom';
    // we are going from top to bottom so it should be from the end to the start
    let nextCollapseData;
    const registry = getRegistry();
    if (direction === 'Bottom') {
      // we're at the collapsed level so need to go to the next one from the bottom to top if it exists
      if (currentCollapseLevelNode) {
        // prev because if move to bottom so the next level to collapse is the previous one
        nextCollapseData = currentCollapseLevelNode.prev?.model;
      } else {
        // set the current level as the last one. Currently it supports only moving to bottom
        nextCollapseData = registry.tail?.model;
      }
    }

    return nextCollapseData || null;
  };

  const getPrevCollapseLevelData = (directionOuter?: DirectionType) => {
    const direction = directionOuter || 'Bottom';
    let prevCollapseData;

    if (direction === 'Bottom') {
      if (currentCollapseLevelNode) {
        // next because we move to top from the bottom so should just stop at the next level (which is hidden right now)
        prevCollapseData = currentCollapseLevelNode.next?.model;
      }
    }

    return prevCollapseData || null;
  };

  const moveToNextCollapseLevel = (directionOuter?: DirectionType) => {
    const direction = directionOuter || 'Bottom';
    let nextCollapseNode: DoubleLinListNode<LevelDataType> | null = null;
    const registry = getRegistry();
    if (direction === 'Bottom') {
      if (currentCollapseLevelNode) {
        setState((prevState) => ({
          ...prevState,
          // prev because we are moving from top to bottom and the next level is the previous one which is above
          currentCollapseLevelNode: currentCollapseLevelNode.prev,
          status: StatusEnum.COLLAPSED,
        }));
      } else {
        // there is no any current level so we should set the last one as we are collapsing to the bottom this thing
        nextCollapseNode = registry.tail;
        setState((prevState) => ({
          ...prevState,
          currentCollapseLevelNode: nextCollapseNode,
          status: StatusEnum.COLLAPSED,
        }));
      }
    }

    return nextCollapseNode?.model || null;
  };

  const moveToPrevCollapseLevel = (directionOuter?: DirectionType) => {
    const direction = directionOuter || 'Bottom';
    let prevCollapseData: LevelDataType | null = null;

    if (direction === 'Bottom') {
      if (currentCollapseLevelNode) {
        // because we open from bottom to top the previous node is actually the next one which is below and hidden
        let prevNode = currentCollapseLevelNode.next;
        if (prevNode) {
          prevCollapseData = prevNode?.model;

          setState((prevState) => ({
            ...prevState,
            currentCollapseLevelNode: prevNode,
            status: StatusEnum.COLLAPSED,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            currentCollapseLevelNode: null,
            status: StatusEnum.OPENED,
          }));
        }
      }
      return prevCollapseData;
    }

    return prevCollapseData || null;
  };

  const reset = () => {
    setState((prevState) => ({
      ...prevState,
      currentCollapseLevelNode: null,
    }));
    registryRef.current.reset();
  };

  return (
    <BottomSheetCtx.Provider
      value={{
        status,
        currentCollapseLevelNode,
        addCollapseLevelData,
        getCurrentCollapseLevelData,
        getNextCollapseLevelData,
        getPrevCollapseLevelData,
        moveToNextCollapseLevel,
        moveToPrevCollapseLevel,
        reset,
      }}
    >
      {children}
    </BottomSheetCtx.Provider>
  );
};
