'use client';
import { ColorPicker } from '@10x/foundation/src/components/color-picker/ColorPicker';
import {
  TextInput,
  TextInputRefType,
  validators,
} from '@foundationPathAlias/components';
import { hexToRGBA, rgbaToValuesObject } from '@foundationPathAlias/utilities';
import { initialColor } from '@mainApp/src/stores';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityColorPickerMobile } from './CommunityColorPickerMobile';
import {
  GetColorContrastValidatorErrorMessages,
  getColorContrastValidator,
} from './getColorContrastValidator';
import { ColorType, SavedColorType } from './types';

type CommunityColorsMobileProps = {
  initialPrimaryColor: ColorType;
  errorMessages: GetColorContrastValidatorErrorMessages;
  dmLabel: string;
  dmPrimaryColor: ColorType | null;
  lmLabel: string;
  lmPrimaryColor: ColorType | null;
  isFullWindowMode?: boolean;
  setColor: (type: 'dm' | 'lm', color: ColorType) => void;
  setLmPrimaryBlack: () => void;
  setDmPrimaryWhite: () => void;
  onColorPickerTrigger: (color: SavedColorType) => void;
  onResetSelectedColorData: () => void;
  selectedColorData: SavedColorType | null;
};

const _CommunityColorsMobile = (props: CommunityColorsMobileProps) => {
  const {
    initialPrimaryColor,
    errorMessages,
    dmLabel,
    dmPrimaryColor,
    lmLabel,
    lmPrimaryColor,
    isFullWindowMode,
    setLmPrimaryBlack,
    setDmPrimaryWhite,
    setColor,
    onColorPickerTrigger,
    selectedColorData,
  } = props;

  const lmInputRef = React.useRef<TextInputRefType | null>(null);
  const dmInputRef = React.useRef<TextInputRefType | null>(null);
  const { t } = useTranslation(['common']);

  const lmColor = lmPrimaryColor || initialPrimaryColor;
  const dmColor = dmPrimaryColor || initialPrimaryColor;

  React.useEffect(() => {
    if (!lmInputRef.current || !lmColor) {
      return;
    }

    if (lmInputRef.current.getValue() !== lmColor.hex) {
      lmInputRef.current.setValue(lmColor.hex);
      if (!lmPrimaryColor) {
        setColor('lm', lmColor);
      }
    }
  }, [lmColor]);

  React.useEffect(() => {
    if (!dmInputRef.current || !dmColor) {
      return;
    }

    if (dmInputRef.current.getValue() !== dmColor.hex) {
      dmInputRef.current.setValue(dmColor.hex);

      if (!dmPrimaryColor) {
        setColor('dm', dmColor);
      }
    }
  }, [dmColor]);

  const getInputHandler =
    (type: 'dm' | 'lm') =>
    (val = '') => {
      // @ts-ignore
      const pureRgba = props[`${type}PrimaryColor`].pureRgba;
      const rgba = hexToRGBA(val, pureRgba.a);

      const finalColor = {
        rgba: rgba || initialColor.rgba,
        hex: val,
        pureRgba: rgba ? rgbaToValuesObject(rgba) : pureRgba,
      };
      setColor(type, finalColor);
    };

  const maxCharsLimit = 7;
  const maxCharValidatorErr = t('errors.maxCharLength', {
    maxCharCount: maxCharsLimit,
  });

  if (isFullWindowMode && selectedColorData) {
    const color = selectedColorData.type === 'lm' ? lmColor : dmColor;
    return (
      <div className="h-[362px] w-full rounded-[4px]">
        <ColorPicker
          rootCn="w-full h-full shadow-none px-0"
          saturationWrapperCn="w-full h-full"
          hueWrapperCn="h-[16px] mt-[32px]"
          inputWrapperCn="text-sm14R themed-text mt-[32px]"
          color={color.rgba as string}
          Pointer={() => (
            <div className="h-[24px] w-[24px] rounded-full border-[2px] border-background-primary" />
          )}
          HuePointer={() => (
            <div className="h-[16px] w-[16px] rounded-full border-[2px] border-background-primary" />
          )}
          showInput={true}
          onChange={(color: any) => {
            const rgba = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
            const res = {
              rgba,
              hex: color.hex,
              pureRgba: color.rgb,
            };
            setColor(selectedColorData.type, res);
          }}
        />
      </div>
    );
  }

  return (
    <div className="text-left">
      <div className="mt-[24px]">
        <label
          htmlFor="lmPrimaryColor"
          className="mb-[4px] block text-body16SB text-text-primary dark:text-text-primary-dark"
        >
          {lmLabel}
        </label>
        <TextInput
          labelCn="items-center"
          inputName="lmPrimaryColor"
          iconWrapperCn="w-[28px] h-[28px]"
          ref={(inputRef: TextInputRefType) => {
            if (lmInputRef.current) return;
            lmInputRef.current = inputRef;
          }}
          rightSideContent={
            <CommunityColorPickerMobile
              onTrigger={() => {
                onColorPickerTrigger?.({
                  type: 'lm',
                  color: lmColor,
                });
              }}
              color={lmColor as ColorType}
            />
          }
          validators={[
            validators.getMaxLengthValidator(
              maxCharsLimit,
              maxCharValidatorErr
            ),
            getColorContrastValidator({
              type: 'lm',
              onSetColorClick: setLmPrimaryBlack,
              errorMessages,
            }),
          ]}
          onChange={getInputHandler('lm')}
        />
      </div>
      <div className="relative mt-[24px]">
        <label
          htmlFor="dmPrimaryColor"
          className="mb-[4px] block text-body16SB text-text-primary dark:text-text-primary-dark"
        >
          {dmLabel}
        </label>
        <TextInput
          labelCn="items-center"
          inputName="dmPrimaryColor"
          iconWrapperCn="w-[28px] h-[28px]"
          ref={(inputRef: TextInputRefType) => {
            if (dmInputRef.current) return;
            dmInputRef.current = inputRef;
          }}
          rightSideContent={
            <CommunityColorPickerMobile
              onTrigger={() => {
                onColorPickerTrigger?.({
                  type: 'dm',
                  color: dmColor,
                });
              }}
              color={dmColor as ColorType}
            />
          }
          validators={[
            validators.getMaxLengthValidator(
              maxCharsLimit,
              maxCharValidatorErr
            ),
            getColorContrastValidator({
              type: 'dm',
              onSetColorClick: setDmPrimaryWhite,
              errorMessages,
            }),
          ]}
          onChange={getInputHandler('dm')}
        />
      </div>
    </div>
  );
};

export const CommunityColorsMobile = observer(_CommunityColorsMobile);
