import {
  getAbsoluteZoom,
  getZoomFactor,
} from 'advanced-cropper/extensions/absolute-zoom';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { CropperFade, CropperRef, CropperState } from 'react-advanced-cropper';

import { RangeSlider } from '@foundationPathAlias/components/range-slider';
import { classNames } from '@foundationPathAlias/utilities';

export enum CropperOrientationEnum {
  // Portrait = 'Portrait',
  // Landscape = 'Landscape',
  // Square = 'Square',
  // can't use portrait because should crop the max possible width of the community card image according the max and min width of the image and requirements
  WidePortraitLike = 'WidePortraitLike',
  Circle = 'Circle',
}

export const orientationRatioRegistry = {
  // [CropperOrientationEnum.Portrait]: 2 / 3,
  // [CropperOrientationEnum.Landscape]: 3 / 2,
  // [CropperOrientationEnum.Square]: 1 / 1,
  [CropperOrientationEnum.WidePortraitLike]: 1.0078,
  [CropperOrientationEnum.Circle]: 1 / 1,
};

export type CropperSnapshot = {
  zoomValue: number | null;
  state: CropperState | null | undefined;
};

type CropperWrapperProps = {
  cropper: CropperRef;
  loading: boolean;
  loaded: boolean;
  snapshot?: CropperSnapshot;
  /** classes for the root div el */
  classNameRoot?: string;
  /** don't override it. It comes from the cropper itself to the cropperFade */
  className?: string;
  classNameCropperFade?: string;
  classNameRangeSliderWrapper?: string;
  children: JSX.Element;
  style?: CSSProperties;
  onZoomChange?: (snapshot: CropperSnapshot) => void;
  renderRangeSlider?: (rangeSlider: JSX.Element) => JSX.Element;
};

export const CustomWrapper = (props: CropperWrapperProps) => {
  const {
    cropper,
    children,
    loaded,
    snapshot,
    classNameRoot,
    classNameRangeSliderWrapper,
    classNameCropperFade,
    className,
    onZoomChange,
    renderRangeSlider,
  } = props;

  const [rangeValues, setRangeValues] = useState({ values: [0] });

  const wasZoomInitializedRef = useRef<boolean>(false);

  const state = cropper.getState();

  const settings = cropper.getSettings();

  const currentAbsoluteZoom = getAbsoluteZoom(state, settings, false);
  useEffect(() => {
    const initialZoomVal = snapshot?.zoomValue;

    if (!cropper.isLoaded()) return;

    if (
      initialZoomVal !== null &&
      initialZoomVal !== undefined &&
      !wasZoomInitializedRef.current
    ) {
      cropper.setState(snapshot?.state as CropperState, {
        transitions: false,
      });

      setRangeValues({ values: [initialZoomVal] });
      wasZoomInitializedRef.current = true;
    } else {
      if (cropper.isLoaded()) {
        const currAbsZoom = currentAbsoluteZoom.toFixed(2);
        const currValues = rangeValues.values[0].toFixed(2);

        onZoomChange?.({
          zoomValue: currentAbsoluteZoom,
          state: state,
        });

        // handling outer reset
        if (currAbsZoom !== currValues) {
          setRangeValues({ values: [currentAbsoluteZoom] });
        }
      }
    }
  }, [snapshot?.zoomValue, currentAbsoluteZoom]);

  let rangeSlider = (
    <RangeSlider
      range={{
        min: 0,
        max: 1,
      }}
      step={0.05}
      values={rangeValues.values}
      onChange={(values) => {
        const newVal = values[0];
        setRangeValues({ values });
        onZoom(newVal);
      }}
    />
  );

  if (renderRangeSlider) {
    rangeSlider = renderRangeSlider(rangeSlider);
  }

  const onZoom = (val: number, transitions: boolean = true) => {
    const zF = getZoomFactor(state, settings, val);
    onZoomChange?.({
      zoomValue: val,
      state: state,
    });
    cropper.zoomImage(zF, {
      transitions,
    });
  };

  return (
    <div className={classNames('relative h-full', classNameRoot)}>
      <CropperFade
        className={classNames(
          'cropper-custom-wrapper',
          className,
          classNameCropperFade
        )}
        visible={loaded}
      >
        {children}
      </CropperFade>
      <div
        className={classNames('relative w-full', classNameRangeSliderWrapper)}
      >
        {rangeSlider}
      </div>
    </div>
  );
};
