import { SavedColorType } from '@mainApp/src/components/common/community-colors/types';
import { createContext, ReactNode, useState } from 'react';

type BottomSheetActionsType = {
  open: null | (() => void);
  close: null | (() => void);
  transformToFullWindow: null | (() => void);
  transformToOriginal: null | (() => void);
  resetBottomSheetCtx: null | (() => void);
};

type InitialStateType = {
  selectedColorData: SavedColorType | null;
  isBottomSheetIfFullWindowMode: boolean;
  bottomSheetActions: BottomSheetActionsType;
};

type MobileCtxType = InitialStateType & {
  setSelectedColorData: (color: SavedColorType | null) => void;
  setIsBottomSheetIfFullWindowMode: (
    isBottomSheetIfFullWindowMode: boolean
  ) => void;
  setBottomSheetActions: (actions: Partial<BottomSheetActionsType>) => void;
  openBottomSheetInFullMode: () => void;
  restoreBottomSheetToNormalMode: () => void;
  setResetBottomSheetCtx: (resetCtx: (() => void) | null) => void;
};

const initialState: InitialStateType = {
  selectedColorData: null,
  isBottomSheetIfFullWindowMode: false,
  bottomSheetActions: {
    open: null,
    close: null,
    transformToFullWindow: null,
    transformToOriginal: null,
    resetBottomSheetCtx: null,
  },
};

export const MobileCtx = createContext<MobileCtxType | undefined>(undefined);

export const MobileCtxProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState(initialState);

  const {
    selectedColorData,
    isBottomSheetIfFullWindowMode,
    bottomSheetActions,
  } = state;

  const setSelectedColorData = (color: SavedColorType | null) => {
    setState((prevState) => ({
      ...prevState,
      selectedColorData: color,
    }));
  };

  const setIsBottomSheetIfFullWindowMode = (
    isBottomSheetIfFullWindowMode: boolean
  ) => {
    setState((prevState) => ({
      ...prevState,
      isBottomSheetIfFullWindowMode,
    }));
  };

  const openBottomSheetInFullMode = () => {
    bottomSheetActions.transformToFullWindow?.();
    setIsBottomSheetIfFullWindowMode(true);
  };
  const restoreBottomSheetToNormalMode = () => {
    bottomSheetActions.transformToOriginal?.();
    setIsBottomSheetIfFullWindowMode(false);
  };

  const setBottomSheetActions = (actions: Partial<BottomSheetActionsType>) => {
    setState((prevState) => ({
      ...prevState,
      bottomSheetActions: {
        ...prevState.bottomSheetActions,
        ...actions,
      },
    }));
  };

  const setResetBottomSheetCtx = (reset: null | (() => void)) => {
    setState((prevState) => ({
      ...prevState,
      bottomSheetActions: {
        ...prevState.bottomSheetActions,
        resetBottomSheetCtx: reset,
      },
    }));
  };

  return (
    <MobileCtx.Provider
      value={{
        bottomSheetActions,
        selectedColorData,
        isBottomSheetIfFullWindowMode,
        setSelectedColorData,
        setIsBottomSheetIfFullWindowMode,
        setBottomSheetActions,
        openBottomSheetInFullMode,
        restoreBottomSheetToNormalMode,
        setResetBottomSheetCtx,
      }}
    >
      {children}
    </MobileCtx.Provider>
  );
};
