import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { animationConfig } from '@foundationPathAlias/components';
import { UploadImageContainer } from '@mainApp/src/components/common';
import { useMobileCtx } from '@mainApp/src/components/common/create-community-stepper/data';
import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';

import { StepPanel } from './StepPanel';

export function _Step2Mobile() {
  const {
    steps: { step2 },
  } = useInjection(IOC_TOKENS.createCommunityStepperStore);

  const { t } = useTranslation(['common', 'create-community']);

  const { bottomSheetActions, setIsBottomSheetIfFullWindowMode } =
    useMobileCtx();

  return (
    <StepPanel
      stepStore={step2}
      title={t('create-community:step2Title')}
      description={t('create-community:step2Description')}
    >
      <UploadImageContainer
        cropperSnapshot={step2.cropperSnapshot}
        fileSrc={step2.logo.fileUrl}
        croppedFileSrc={step2.logo.croppedFileUrl}
        onFileCatch={(files: FileList, fileUrl: string) => {
          bottomSheetActions.transformToFullWindow?.();
          setIsBottomSheetIfFullWindowMode(true);
          setTimeout(() => {
            step2.setLogo(files[0], fileUrl);
          }, animationConfig.duration);
        }}
        onFileCrop={step2.setLogo}
        setCropperSnapshot={step2.setCropperSnapshot}
        onCancel={step2.reset}
        onSave={step2.triggerUploading}
        uploadingStatus={step2.logo.status}
        uploadingPercentage={step2.logo.uploadingPercent}
        error={step2.logo.error}
        fileName={step2.logo.fileName}
        fileSize={step2.logo.fileSize}
        hideCropperActions={true}
      />
    </StepPanel>
  );
}

export const Step2Mobile = observer(_Step2Mobile);
