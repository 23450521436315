import { format } from 'date-fns';

export class DateSeparatorModel {
  date: string;
  id: string;
  serverData: {
    id: string;
    timestamp: number;
  };

  /**
   *
   * @param date - expected format is 'Decemper 1, 2024'
   */
  constructor(date: string, timestamp: number) {
    this.date = date;
    this.id = `separator-${date}`;
    this.serverData = {
      id: this.id,
      timestamp: timestamp,
    };
  }

  static timestampToDateString(timestamp: number): string {
    return `${format(timestamp, 'LLLL d')}, ${format(timestamp, 'yyyy')}`;
  }
}
