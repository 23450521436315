import { classNames } from '@10x/foundation/src/utilities';

import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

import { useTranslation } from 'react-i18next';

import { ProgressBarCircular } from '@foundationPathAlias/components/progress/progress-bar-circular';

type Props = {
  error: string | void;
  onClose: () => void;
  uploading: boolean;
  uploadingPercentage: number;
  fileName: string | null;
  fileSize: number | null;
  croppedFileSrc: string | null | undefined;
};

export const LoadedImagePreview = ({
  error,
  onClose,
  uploading,
  uploadingPercentage,
  fileName,
  fileSize,
  croppedFileSrc,
}: Props) => {
  const isError = !uploading && error;
  const { t } = useTranslation(['common']);
  return (
    <div
      className={classNames(
        'max-w-full grid min-h-[65px] w-full grid-cols-[auto_1fr_auto] items-center rounded-[5px] border-[1px] border-element-normal p-[12px] dark:border-element-normal-dark md:max-w-[348px]',
        isError && 'border-element-error dark:border-element-error-dark'
      )}
    >
      <div>
        {uploading ? (
          <ProgressBarCircular
            progressPercentage={uploadingPercentage}
            cn={'w-[25px] h-[25px]'}
            pathStroke="rgba(95, 92, 255, 1)"
            trailStroke="rgba(95, 92, 255, .3)"
          />
        ) : (
          <div>
            {isError ? (
              <ExclamationCircleIcon className="themed-text-error h-[26px] w-[26px] " />
            ) : (
              <img
                className="h-[44px] w-[44px] rounded-[4px]"
                src={croppedFileSrc as string}
                alt={fileName || 'image preview'}
              />
            )}
          </div>
        )}
      </div>

      <div className="mx-[12px] overflow-hidden text-ellipsis whitespace-nowrap">
        <div className="flex justify-between">
          <h5 className="themed-text inline justify-between truncate text-body16R">
            {fileName}
          </h5>
        </div>

        {uploading && (
          <span className="dark:text-placeholder-dark block text-left text-exstraSm12R text-text-placeholder">
            {fileSize}
          </span>
        )}
        {Boolean(error) && (
          <span className="themed-text-error block text-left text-exstraSm12R ">
            {error as string}
          </span>
        )}
      </div>

      <div>
        <button
          className={classNames(
            'text-sm14T text-primary-100-dark',
            isError && 'themed-text-error'
          )}
          type="button"
          onClick={onClose}
        >
          {t(
            uploading
              ? 'common:cancel'
              : isError
              ? 'common:disacrd'
              : 'common:change'
          )}
        </button>
      </div>
    </div>
  );
};
