import { classNames } from '@10x/foundation/src/utilities';
import type { FC } from 'react';
import { useRef } from 'react';
import type { DropTargetMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

export interface TargetBoxProps {
  onFileCatch: (files: FileList) => void;
}

export const _DropZone: FC<TargetBoxProps> = (props) => {
  const { onFileCatch } = props;
  const dimensionsStore = useInjection(IOC_TOKENS.dimensionsStore);
  // basically it's just to see when the file had been dropped
  const pickedFileSizeRef = useRef(undefined);
  const isNonImageUploadRef = useRef(false);

  const { t } = useTranslation(['common']);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: FileList }) {
        if (onFileCatch) {
          const files = item.files;
          if (files && files.length) {
            onFileCatch(files);
          }
        }
      },
      canDrop(item: any) {
        isNonImageUploadRef.current = false;
        const file = item.files[0];
        const fileSize = file?.size;
        pickedFileSizeRef.current = fileSize;

        const isImage = file?.type.includes('image');
        let res = true;
        if (file && fileSize > 100000 * 50) {
          res = false;
        }

        if (file && !isImage) {
          res = false;
          isNonImageUploadRef.current = true;
        }
        return res;
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props]
  );

  const isActive = canDrop && isOver;

  const isMaxSizeError = !canDrop && Boolean(pickedFileSizeRef.current);

  return (
    <div
      ref={drop}
      className={classNames(
        'dropzone animation-classic flex',
        isActive && 'border-animation file-over'
      )}
    >
      <div
        className={classNames(
          'animation-classic flex flex-1 flex-col items-center justify-center rounded-[5px]',
          isActive &&
            'bg-color-5 shadow-dropzone dark:bg-color-5-dark dark:shadow-dropzone-dark'
        )}
      >
        <div className="flex items-center space-x-[2px]">
          <label className="text-action-primary-underline flex h-[24px] items-center ">
            {t('upload.clickToUpload')}
            <input
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => {
                const files = e.target.files;
                if (files && files.length) {
                  onFileCatch(files);
                }
              }}
              type="file"
              className="hidden"
              aria-label="Upload"
            />
          </label>
          {!dimensionsStore.isMobile && (
            <span className="text-body14R themed-text-secondary">
              {t('upload.orDnD')}
            </span>
          )}
        </div>
        <p
          className={classNames(
            'mt-[2px]',
            isMaxSizeError
              ? 'text-sm13D text-element-error dark:text-element-error-dark'
              : 'themed-text-secondary text-exstraSm12R'
          )}
        >
          {isNonImageUploadRef.current
            ? t('upload.onlyImage')
            : t('upload.maxSizeMb', { size: 50 })}
        </p>
      </div>
    </div>
  );
};

export const DropZone = observer(_DropZone);
