import { classNames } from '@foundationPathAlias/utilities';

export type StepContentTitlePropsType = {
  title: string;
  description: string;
  cn?: string;
  titleCn?: string;
  descriptionCn?: string;
};

export const StepContentTitle = ({
  title,
  description,
  cn,
  titleCn,
  descriptionCn,
}: StepContentTitlePropsType) => (
  <div className={classNames('themed-text mb-[28px] text-left', cn)}>
    <h5 className={classNames('pb-[4px] text-subheadingSB', titleCn)}>
      {title}
    </h5>
    <p className={classNames('themed-text text-body16R', descriptionCn)}>
      {description}
    </p>
  </div>
);
