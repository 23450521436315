import { XMarkIcon } from '@heroicons/react/24/outline';

import {
  SquareIconButtonBaseMedium,
  SquareIconButtonBaseMediumPropsType,
} from './SquareIconButtonBaseMedium';

export const CloseButton = ({
  ...props
}: SquareIconButtonBaseMediumPropsType) => {
  return <SquareIconButtonBaseMedium Icon={XMarkIcon} {...props} />;
};
