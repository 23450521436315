import 'react-tabs/style/react-tabs.css';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';

import { Actions } from './shared/Actions';
import { StepsColumn } from './steps-panel-column/StepsColumn';

import { CommunityPreview } from './steps-previews/CommunityPreview';
import { CommunityPreviewMobile } from './steps-previews/CommunityPreviewMobile';

import { BackButton } from '@foundationPathAlias/components';

import { CompletedUI } from './CompletedUI';

import { CreateCommunityStepperMobileContent } from './CreateCommunityStepperMobileContent';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { MobileCtxProvider } from './data';

import { CloseButton } from './shared/CloseButton';

const stepsContentRegistry = {
  step1: {
    mobile: <CommunityPreviewMobile />,
    desktop: <CommunityPreview />,
  },
  step2: {
    mobile: <CommunityPreviewMobile />,
    desktop: <CommunityPreview />,
  },
  step3: {
    mobile: <CommunityPreviewMobile />,
    desktop: <CommunityPreview />,
  },
  step4: {
    mobile: <CommunityPreviewMobile />,
    desktop: <CommunityPreview />,
  },
  step5: {
    mobile: <CommunityPreviewMobile />,
    desktop: <CommunityPreview />,
  },
};

export function _CreateCommunityStepper() {
  const { dimensionsStore, createCommunityStepperStore } = useMultipleInjection(
    [IOC_TOKENS.dimensionsStore, IOC_TOKENS.createCommunityStepperStore]
  );

  const { t } = useTranslation(['common', 'create-community']);

  const activeStepContent =
    stepsContentRegistry[createCommunityStepperStore.activeStepStoreKey];

  const handleClose = () => {
    createCommunityStepperStore.setModal(false);
  };

  const Mobile = (
    <MobileCtxProvider>
      <CreateCommunityStepperMobileContent
        activeStepContent={activeStepContent}
      />
    </MobileCtxProvider>
  );

  const Desktop = (
    <div className="themed-layout themed-text my-[30px] flex max-h-full w-full rounded-[10px]">
      <div className="flex min-w-[692px] flex-1 flex-col">
        <div className="flex h-[56px] items-center justify-between border-b-[1px] border-b-element-normal px-[16px] dark:border-b-element-normal-dark">
          <div className="flex items-center justify-start space-x-[8px]">
            {createCommunityStepperStore.canMoveBack && (
              <BackButton
                className="dark:text-secondary-dark text-text-secondary"
                onClick={createCommunityStepperStore.backToPrevStep}
              />
            )}

            <h4 className="text-body18SB">
              {t('create-community:createCommunity')}
            </h4>
          </div>

          <div className="flex items-center">
            <CloseButton onClick={handleClose} />
          </div>
        </div>

        <div className="grid w-full flex-1 grid-cols-[minmax(auto,386px)_1fr]">
          <div className="overflow-auto border-r-[1px] border-element-subtle dark:border-element-subtle-dark">
            <StepsColumn />
          </div>

          <div className="flex w-full flex-1 items-center justify-center py-[12px] pl-[24px] pr-[14px] md:w-[42vw] bigheight:py-[24px]">
            {activeStepContent.desktop}
          </div>
        </div>

        <div className="flex justify-end border-t-[1px] border-t-element-normal px-[16px] py-[16px] dark:border-t-element-normal-dark md:py-[12px]">
          <Actions />
        </div>
      </div>
    </div>
  );

  const finalContent = dimensionsStore.isMobile ? Mobile : Desktop;
  if (createCommunityStepperStore.completed) {
    // on mobile it must be set into global bottomsheet in the CreateCommunityStepperModal
    return dimensionsStore.isMobile ? null : (
      <div
        className="themed-layout m-auto mx-[8px] max-w-[480px] self-center overflow-auto rounded-[10px] p-[16px] md:p-[24px] md:pt-0"
        onPointerMove={(e) => {
          e.stopPropagation();
        }}
      >
        <CompletedUI />
      </div>
    );
  }

  return <DndProvider backend={HTML5Backend}>{finalContent}</DndProvider>;
}

export const CreateCommunityStepper = observer(_CreateCommunityStepper);
